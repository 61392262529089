export const DASHBOARD_LOADING_CHART = "DASHBOARD_LOADING_CHART",
  DASHBOARD_SELECT_SCHOOL = "DASHBOARD_SELECT_SCHOOL",
  DASHBOARD_WIDGET_MODE = "DASHBOARD_WIDGET_MODE";

export const dashboardIsLoadingChart = ({ isLoading, chart }) => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_LOADING_CHART,
      isLoading,
      chart,
    });
  };
};

export const dashboardSelectSchool = ({ school, chart }) => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_SELECT_SCHOOL,
      selected: school,
      chart,
    });
  };
};

export const dashboardWidgetMode = ({ mode, chart }) => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARD_WIDGET_MODE,
      mode,
      chart,
    });
  };
};
