import firebase from '../constants/firebase';
import { CLASSES, STUDENTS } from '../constants/collections';
import { getAllStudentsInSection } from './sections';
import { asyncForEach } from './Utils';
import { addClassToStudent } from './students';

export const addStudentToClass = async (classId, studentId, sy, unenroll = false) => {
	const db = firebase.firestore();

	// Update enrollees field
	const classRef = db.collection(CLASSES).doc(classId);
	await classRef.set(
		{
			enrollees: !unenroll ? firebase.firestore.FieldValue.arrayUnion(studentId) : firebase.firestore.FieldValue.arrayRemove(studentId),
			lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
		},
		{ merge: true }
	);

	const studentClassRef = classRef.collection(STUDENTS).doc(studentId);
	if (!unenroll) {
		// Add subcollection "students"
		await studentClassRef.set(
			{
				grades: {
					'1Q': null,
					'2Q': null,
					'3Q': null,
					'4Q': null,
					final: null,
					remarks: null
				},
				attendance: []
			},
			{ merge: true }
		);
	} else {
		await studentClassRef.delete()
	}

	return await addClassToStudent(studentId, classId, sy, unenroll);
};

export const addSectionStudentsToClass = async (classId, sectionId, sy) => {
	const sectionStudents = await getAllStudentsInSection(sectionId, sy);
	return await asyncForEach(sectionStudents, async (studentId) => {
		await addStudentToClass(classId, studentId, sy);
	});
};

export const fetchClasses = async (classes) => {
	const db = firebase.firestore();
	let fetchedClasses = [];
	await asyncForEach(classes, async (classId) => {
		const classRef = db.collection(CLASSES).doc(classId);
		const classSnap = await classRef.get();
		if (classSnap.exists) {
			const subjectClass = { id: classSnap.id, ...classSnap.data() };
			fetchedClasses.push(subjectClass);
		}
	});

	return fetchedClasses;
};

export const fetchStudentClassRecord = async (classes, studentId) => {
	const db = firebase.firestore();
	let fetchedClassesRecord = [];
	await asyncForEach(classes, async (classId) => {
		const classStudentRef = db.collection(CLASSES).doc(classId).collection(STUDENTS).doc(studentId);
		const classStudentSnap = await classStudentRef.get();
		if (classStudentSnap.exists) {
			const studentClassRecord = { id: classId, ...classStudentSnap.data() };
			fetchedClassesRecord.push(studentClassRecord);
		}
	});

	return fetchedClassesRecord;
};

export const getClassUserRole = (subjectClass, userId) => {
	if (!subjectClass || !userId) {
		return;
	}

	if (subjectClass.teacher && subjectClass.teacher.value === userId) {
		return 'teacher';
	} else if (subjectClass.enrollees && subjectClass.enrollees.includes(userId)) {
		return 'student';
	} else {
		return;
	}
};

export const addGoogleClassroomClassToClass = async ({ classId, gClass }) => {
	const db = firebase.firestore();
	const classRef = db.collection(CLASSES).doc(classId);
	return await classRef.set(
		{
			googleClassroomClass: gClass,
			lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
		},
		{ merge: true }
	);
};

export const addClassroomFeedToClass = async ({ regFeed, classId }) => {
	const db = firebase.firestore();
	const classRef = db.collection(CLASSES).doc(classId);
	return await classRef.set(
		{
			...regFeed,
			lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
		},
		{ merge: true }
	);
};

export const addClassroomStudentsToClass = async ({ students, classId }) => {
	const db = firebase.firestore();
	const classRef = db.collection(CLASSES).doc(classId);
	return await classRef.set(
		{
			googleClassroomStudents: students,
			lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
		},
		{ merge: true }
	);
};
