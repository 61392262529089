import {
  DASHBOARD_LOADING_CHART,
  DASHBOARD_SELECT_SCHOOL,
  DASHBOARD_WIDGET_MODE,
} from "./actions";

const schoolSwitcherDefault = {
  isLoading: false,
  selected: null,
  mode: "all",
};
const initState = {
  enrolledStudentsSchool: schoolSwitcherDefault,
  averageGradesSchool: schoolSwitcherDefault,
  monthlyAbsencesSchool: schoolSwitcherDefault,
};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case DASHBOARD_LOADING_CHART:
      return {
        ...state,
        [action.chart]: {
          ...state[action.chart],
          isLoading: action.isLoading,
        },
      };
    case DASHBOARD_SELECT_SCHOOL:
      return {
        ...state,
        [action.chart]: {
          ...state[action.chart],
          selected: action.selected,
        },
      };
    case DASHBOARD_WIDGET_MODE:
      return {
        ...state,
        [action.chart]: {
          ...state[action.chart],
          mode: action.mode,
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
