import map from "lodash/map";
import find from "lodash/find";
import {
  schoolLevels,
  schoolType,
  schoolYears,
  syMonths,
} from "../constants/schools";
import join from "lodash/join";
import compact from "lodash/compact";
import firebase from "../constants/firebase";
import {
  SCHOOLS,
  STUDENTS,
  TEACHERS,
  STAFFS,
  SECTIONS,
  EMPLOYEES,
  IDX_STUDENTS,
  ATTENDANCE,
  ABSENCES,
  IDX_SCHOOLS,
} from "../constants/collections";
import round from "lodash/round";

export const getSchoolLevels = (levels) => {
  return join(
    compact(
      map(levels, (level) => {
        return find(schoolLevels, ["value", level]).label || null;
      })
    ),
    ", "
  );
};

export const getSchoolType = (type) => {
  return find(schoolType, ["value", type]).label || "";
};

export const searchSchool = async (search, limit = 0) => {
  try {
    const db = firebase.firestore();
    const keywords = search.toLowerCase();
    let schoolRef = db
      .collection(IDX_SCHOOLS)
      .where("keywords", "array-contains", keywords);
    if (limit) {
      schoolRef.limit(limit);
    }
    return await schoolRef.get();
  } catch (error) {
    throw error;
  }
};

export const addStudentToSchool = async (
  school,
  student,
  sy,
  gender = "Male",
  unenroll = false
) => {
  const db = firebase.firestore();
  const schoolRef = db
    .collection(SCHOOLS)
    .doc(school)
    .collection(STUDENTS)
    .doc(sy);
  return await schoolRef.set(
    {
      students: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(student)
        : firebase.firestore.FieldValue.arrayRemove(student),
      [gender.toLowerCase()]: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(student)
        : firebase.firestore.FieldValue.arrayRemove(student),
    },
    { merge: true }
  );
};

export const addEmployeeToSchool = async (school, employee, sy, teaching) => {
  const db = firebase.firestore();
  const schoolEmployee = teaching ? TEACHERS : STAFFS;
  const schoolRef = db
    .collection(SCHOOLS)
    .doc(school)
    .collection(schoolEmployee)
    .doc(sy);
  return await schoolRef.set(
    {
      [schoolEmployee]: firebase.firestore.FieldValue.arrayUnion(employee),
    },
    { merge: true }
  );
};

export const addSectionToSchool = async (school, sy, section, grade) => {
  const db = firebase.firestore();
  const schoolRef = db
    .collection(SCHOOLS)
    .doc(school)
    .collection(SECTIONS)
    .doc(sy);
  return await schoolRef.set(
    {
      [grade]: firebase.firestore.FieldValue.arrayUnion(section),
    },
    { merge: true }
  );
};

export const getProfileSchools = async (profileType, profileId, sy) => {
  const db = firebase.firestore();
  const COL = profileType === "employee" ? EMPLOYEES : STUDENTS;
  const profileSchoolRef = db
    .collection(COL)
    .doc(profileId)
    .collection(SCHOOLS)
    .doc(sy);
  const profileSchools = await profileSchoolRef.get();
  const profileSchoolsData = profileSchools.data();

  return profileSchoolsData;
};

export const updateSchoolLogo = (schoolId, logoURI, filename) => {
  const db = firebase.firestore();
  const schoolRef = db.collection(SCHOOLS).doc(schoolId);

  return schoolRef.set({ logo: { filename, url: logoURI } }, { merge: true });
};

export const updateSchoolCoverPhoto = (schoolId, coverPhotoURI, filename) => {
  const db = firebase.firestore();
  const schoolRef = db.collection(SCHOOLS).doc(schoolId);

  return schoolRef.set(
    { coverPhoto: { filename, url: coverPhotoURI } },
    { merge: true }
  );
};

// Assuming you have already imported and initialized Firebase elsewhere in your project
// and that you have a reference to Firestore via `const db = firebase.firestore();`

export async function updateStudentGenderArray() {
  const db = firebase.firestore();

  const schoolsCollectionRef = db.collection(SCHOOLS);
  const schoolSnapshot = await schoolsCollectionRef.get();

  for (const schoolDoc of schoolSnapshot.docs) {
    const schoolId = schoolDoc.id;
    console.log(`Processing school: ${schoolId}`);

    for (const sy of schoolYears) {
      const syValue = sy.value;
      console.log(`Processing school year: ${syValue}`);

      const studentsIndexCollectionRef = db.collection(IDX_STUDENTS);
      const studentsQuery = studentsIndexCollectionRef.where(
        "sy_schools",
        "array-contains",
        `${syValue}_${schoolId}`
      );
      const studentsSnapshot = await studentsQuery.get();

      for (const studentDoc of studentsSnapshot.docs) {
        console.log(`Processing student: ${studentDoc.id}`);

        const gender = studentDoc.data().gender.toLowerCase(); // Convert gender to lowercase
        const studentId = studentDoc.id;

        const studentSubCollectionDocRef = db
          .collection("schools")
          .doc(schoolId)
          .collection("students")
          .doc(syValue);

        // Check if the document exists
        const studentSubCollectionDocSnapshot =
          await studentSubCollectionDocRef.get();

        const genderArrayField = gender === "male" ? "male" : "female";
        if (studentSubCollectionDocSnapshot.exists) {
          console.log("Subcollection document exists");
          // Update the document by adding the student ID to the appropriate array field
          await studentSubCollectionDocRef.update({
            [genderArrayField]:
              firebase.firestore.FieldValue.arrayUnion(studentId),
          });
        } else {
          console.info(
            `Document does not exist: schools/${schoolId}/students/${syValue}`
          );
          // Handle the case where the document does not exist, e.g., create the document or log an error
          // Uncomment the line below if you want to create the document if it doesn't exist
          await studentSubCollectionDocRef.set(
            { [genderArrayField]: [studentId] },
            { merge: true }
          );
        }
      }
    }
  }
}

export function getSchoolAverageGrades(students, sy) {
  let totalGradesSum = 0;
  let totalStudents = 0;

  for (const studentDoc of students) {
    const grades = studentDoc[`${sy}_grades`];
    let studentTotalGrade = 0;
    let subjectCount = 0;

    for (const subjectId in grades) {
      const subjectGrades = grades[subjectId];
      if (!subjectGrades) {
        continue;
      }

      let finalGrade = subjectGrades.final;

      if (finalGrade == null) {
        // If final is null, calculate average from available quarterly grades
        const quarters = ["1Q", "2Q", "3Q", "4Q"];
        const quarterGrades = quarters
          .map((q) => subjectGrades[q])
          .filter((grade) => grade != null);

        if (quarterGrades.length > 0) {
          finalGrade =
            quarterGrades.reduce((sum, grade) => sum + grade, 0) /
            quarterGrades.length;
        }
      }

      if (finalGrade != null) {
        studentTotalGrade += finalGrade;
        subjectCount++;
      }
    }

    if (subjectCount > 0) {
      const studentAverageGrade = studentTotalGrade / subjectCount;
      totalGradesSum += studentAverageGrade;
      totalStudents++;
    }
  }

  // Calculate school average grade
  const schoolAverageGrade =
    totalStudents > 0 ? totalGradesSum / totalStudents : 0;

  return schoolAverageGrade > 0 ? round(schoolAverageGrade, 2) : 0.0;
}

export async function updateAbsences() {
  const db = firebase.firestore();

  // Fetch all sections from the "sections" collection
  const sectionsSnapshot = await db.collection(SECTIONS).get();

  // Loop through all sections
  for (const sectionDoc of sectionsSnapshot.docs) {
    const sectionData = sectionDoc.data();
    const sectionSchool = sectionData.school; // Reference to the school document

    // Fetch all attendance documents from the subcollection "attendance"
    const attendanceSnapshot = await db
      .collection(SECTIONS)
      .doc(sectionDoc.id)
      .collection(ATTENDANCE)
      .get();

    // Skip sections with no attendance records
    if (attendanceSnapshot.empty) {
      continue;
    }

    // Initialize a batch for atomic operations
    const attendanceBatch = db.batch();

    attendanceSnapshot.forEach((attendanceDoc) => {
      const attendanceData = attendanceDoc.data();

      // Check if the document contains "absent", "sy", and "month" fields
      if (
        !attendanceData.absent ||
        !attendanceData.sy ||
        !attendanceData.month
      ) {
        return; // Skip this document if any of the required fields are missing
      }

      const { absent, sy, month } = attendanceData;

      // Calculate the increment value
      const incrementValue = absent / 2;

      // Reference to the absences document in the school collection
      const absenceDocRef = db
        .collection(SCHOOLS)
        .doc(sectionSchool)
        .collection(ABSENCES)
        .doc(sy);

      // Set the month field value using the batch
      attendanceBatch.set(
        absenceDocRef,
        { [month]: firebase.firestore.FieldValue.increment(incrementValue) },
        { merge: true } // Ensure existing fields are not overwritten and the document is created if it doesn't exist
      );
    });

    // Commit the batch after all operations for the current section are added
    await attendanceBatch.commit();
  }

  console.log("Successfully updated absences.");
}

export function getSchoolAverageQuaterlyGrades(students, sy) {
  let totalFinalGradesSum = 0;
  let total1QGradesSum = 0;
  let total2QGradesSum = 0;
  let total3QGradesSum = 0;
  let total4QGradesSum = 0;
  let totalStudents = 0;

  for (const studentDoc of students) {
    const grades = studentDoc[`${sy}_grades`];
    let studentFinalGradeTotal = 0;
    let student1QGradeTotal = 0;
    let student2QGradeTotal = 0;
    let student3QGradeTotal = 0;
    let student4QGradeTotal = 0;
    let subjectCount = 0;

    for (const subjectId in grades) {
      const subjectGrades = grades[subjectId];

      if (!subjectGrades) {
        continue;
      }

      const finalGrade = subjectGrades.final;
      const grade1Q = subjectGrades["1Q"];
      const grade2Q = subjectGrades["2Q"];
      const grade3Q = subjectGrades["3Q"];
      const grade4Q = subjectGrades["4Q"];

      if (finalGrade != null) {
        studentFinalGradeTotal += finalGrade;
      }
      if (grade1Q != null) {
        student1QGradeTotal += grade1Q;
      }
      if (grade2Q != null) {
        student2QGradeTotal += grade2Q;
      }
      if (grade3Q != null) {
        student3QGradeTotal += grade3Q;
      }
      if (grade4Q != null) {
        student4QGradeTotal += grade4Q;
      }
      subjectCount++;
    }

    if (subjectCount > 0) {
      const studentAverageFinalGrade = studentFinalGradeTotal / subjectCount;
      const studentAverage1QGrade = student1QGradeTotal / subjectCount;
      const studentAverage2QGrade = student2QGradeTotal / subjectCount;
      const studentAverage3QGrade = student3QGradeTotal / subjectCount;
      const studentAverage4QGrade = student4QGradeTotal / subjectCount;

      totalFinalGradesSum += studentAverageFinalGrade;
      total1QGradesSum += studentAverage1QGrade;
      total2QGradesSum += studentAverage2QGrade;
      total3QGradesSum += studentAverage3QGrade;
      total4QGradesSum += studentAverage4QGrade;
      totalStudents++;
    }
  }

  // Calculate school average grades
  const schoolAverageFinalGrade =
    totalStudents > 0 ? totalFinalGradesSum / totalStudents : 0;
  const schoolAverage1QGrade =
    totalStudents > 0 ? total1QGradesSum / totalStudents : 0;
  const schoolAverage2QGrade =
    totalStudents > 0 ? total2QGradesSum / totalStudents : 0;
  const schoolAverage3QGrade =
    totalStudents > 0 ? total3QGradesSum / totalStudents : 0;
  const schoolAverage4QGrade =
    totalStudents > 0 ? total4QGradesSum / totalStudents : 0;

  return {
    final: round(schoolAverageFinalGrade, 2),
    "1Q": round(schoolAverage1QGrade, 2),
    "2Q": round(schoolAverage2QGrade, 2),
    "3Q": round(schoolAverage3QGrade, 2),
    "4Q": round(schoolAverage4QGrade, 2),
  };
}

export const getAllMonthAbsencesTotal = (absences) => {
  // Ensure all months from June to May are present with default value 0
  syMonths.forEach((month) => {
    if (!absences[month]) {
      absences[month] = 0;
    }
  });

  // Sort the object keys to match the June to May order
  const sortedResult = syMonths.reduce((sortedAcc, month) => {
    sortedAcc[month] = absences[month];
    return sortedAcc;
  }, {});

  return sortedResult;
};

export const getTotalAbsencesByMonth = (totalAbsences) => {
  const result = totalAbsences.reduce((acc, item) => {
    Object.keys(item).forEach((month) => {
      if (acc[month]) {
        acc[month] = Math.round(acc[month] + item[month]);
      } else {
        acc[month] = Math.round(item[month]);
      }
    });
    return acc;
  }, {});

  return getAllMonthAbsencesTotal(result);
};
