import {
	CREATE_EMPLOYEE_START,
	CREATE_EMPLOYEE_FAIL,
	CREATE_EMPLOYEE_SUCCESS,
	QUERY_NEW_EMPLOYEE_START,
	QUERY_NEW_EMPLOYEE_SUCCESS,
	QUERY_NEW_EMPLOYEE_FAIL
} from './actions';

const initState = {
	creating: false,
	created: false,
	createError: null,
	newEmployeeName: '',
	queryingNew: false,
	queriedNew: false,
	queryNewError: null
};

const employeeReducer = (state = initState, action) => {
	switch (action.type) {
		case QUERY_NEW_EMPLOYEE_START:
			return {
				...state,
				queryingNew: true,
				queriedNew: false,
				queryNewError: null
			};

		case QUERY_NEW_EMPLOYEE_SUCCESS:
			return {
				...state,
				queryingNew: false,
				queriedNew: true,
				queryNewError: null
			};

		case QUERY_NEW_EMPLOYEE_FAIL:
			return {
				...state,
				queryingNew: false,
				queriedNew: false,
				queryNewError: action.error
			};

		case CREATE_EMPLOYEE_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newEmployeeName: action.newEmployee
			};
		case CREATE_EMPLOYEE_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_EMPLOYEE_FAIL:
			return {
				creating: false,
				created: false,
				createError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default employeeReducer;
