import {
	INPUT_SEARCH_KEYWORD,
	SEARCH_REGISTRY_START,
	SEARCH_REGISTRY_SUCCESS,
	SEARCH_REGISTRY_FAIL,
	INPUT_SEARCH_QUERY
} from './actions';
const searchData = {
	keyword: '',
	school: 'all',
	sy: 'all',
	searching: false,
	searched: false,
	searchError: null,
	result: []
};

const userSearchData = {
	keyword: '',
	role: 'all',
	status: 'all',
	school: 'all',
	searching: false,
	searched: false,
	searchError: null,
	result: []
};

const initState = {
	registry: {
		students: searchData,
		employees: searchData,
		users: userSearchData
	},
	school: {
		students: searchData,
		employees: searchData,
		users: userSearchData
	}
};

const searchReducer = (state = initState, action) => {
	switch (action.type) {
		case INPUT_SEARCH_KEYWORD:
			return {
				...state,
				[action.view]: {
					...state[action.view],
					[action.collection]: {
						...state[action.view][action.collection],
						keyword: action.keyword
					}
				}
			};
		case INPUT_SEARCH_QUERY:
			return {
				...state,
				[action.view]: {
					...state[action.view],
					[action.collection]: {
						...state[action.view][action.collection],
						[action.field]: action.value
					}
				}
			};
		case SEARCH_REGISTRY_START:
			return {
				...state,
				[action.view]: {
					...state[action.view],
					[action.collection]: {
						...state[action.view][action.collection],
						// keyword: action.keyword,
						searching: true,
						searched: false,
						searchError: null
					}
				}
			};
		case SEARCH_REGISTRY_SUCCESS:
			return {
				...state,
				[action.view]: {
					...state[action.view],
					[action.collection]: {
						...state[action.view][action.collection],
						searching: false,
						searched: true,
						result: action.result
					}
				}
			};
		case SEARCH_REGISTRY_FAIL:
			return {
				...state,
				[action.view]: {
					...state[action.view],
					[action.collection]: {
						...state[action.view][action.collection],
						searching: false,
						searched: false,
						searchError: action.error.message
					}
				}
			};
		default:
			return {
				...state
			};
	}
};
export default searchReducer;
