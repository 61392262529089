import { CREATE_CLASSROOM_START, CREATE_CLASSROOM_FAIL, CREATE_CLASSROOM_SUCCESS } from './actions';

const initState = {
	creating: false,
	created: false,
	createError: null,
	newClassroomName: ''
};

const classroomReducer = (state = initState, action) => {
	switch (action.type) {
		case CREATE_CLASSROOM_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newClassroomName: action.newBuilding
			};
		case CREATE_CLASSROOM_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_CLASSROOM_FAIL:
			return {
				creating: false,
				created: false,
				createError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default classroomReducer;
