import { addActivityLog } from "../logs/actions";
import {
  CLASSES,
  STUDENTS,
  POSTS,
  GRADE_COMPONENTS,
  CLASS_ACTIVITIES,
} from "../../constants/collections";
import {
  addClassToTeacher,
  removeClassToTeacher,
} from "../../helpers/employees";
import {
  addSectionStudentsToClass,
  fetchClasses,
  fetchStudentClassRecord,
} from "../../helpers/classes";
import { addClassToSection } from "../../helpers/sections";
import {
  addClassToClassroom,
  removeClassToClassroom,
} from "../../helpers/classrooms";
import map from "lodash/map";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import assign from "lodash/assign";
import find from "lodash/find";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import size from "lodash/size";
import sum from "lodash/sum";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import { FAILED, PASSED } from "../../constants/grades";
import { asyncForEach } from "../../helpers/Utils";

export const CREATE_CLASS_START = "CREATE_CLASS_START",
  CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS",
  CREATE_CLASS_FAIL = "CREATE_CLASS_FAIL",
  FETCH_SECTION_CLASSES_START = "FETCH_SECTION_CLASSES_START",
  FETCH_SECTION_CLASSES_SUCCESS = "FETCH_SECTION_CLASSES_SUCCESS",
  FETCH_SECTION_CLASSES_FAIL = "FETCH_SECTION_CLASSES_FAIL",
  FETCH_STUDENT_CLASSES_START = "FETCH_STUDENT_CLASSES_START",
  FETCH_STUDENT_CLASSES_SUCCESS = "FETCH_STUDENT_CLASSES_SUCCESS",
  FETCH_STUDENT_CLASSES_FAIL = "FETCH_STUDENT_CLASSES_FAIL",
  FETCH_TEACHER_CLASSES_START = "FETCH_TEACHER_CLASSES_START",
  FETCH_TEACHER_CLASSES_SUCCESS = "FETCH_TEACHER_CLASSES_SUCCESS",
  FETCH_TEACHER_CLASSES_FAIL = "FETCH_TEACHER_CLASSES_FAIL",
  EDIT_CLASS = "EDIT_CLASS",
  INPUT_CLASS_GRADES = "INPUT_CLASS_GRADES",
  INPUT_STUDENT_GRADE_START = "INPUT_STUDENT_GRADE_START",
  INPUT_STUDENT_GRADE_SUCCESS = "INPUT_STUDENT_GRADE_SUCCESS",
  INPUT_STUDENT_GRADE_FAIL = "INPUT_STUDENT_GRADE_FAIL",
  CREATE_VIDEO_MEETING_START = "CREATE_VIDEO_MEETING_START",
  CREATE_VIDEO_MEETING_FAIL = "CREATE_VIDEO_MEETING_FAIL",
  CREATE_VIDEO_MEETING_SUCCESS = "CREATE_VIDEO_MEETING_SUCCESS",
  SAVE_GRADE_COMPONENTS = "SAVE_GRADE_COMPONENTS",
  SAVE_ACTIVITY_RECORD = "SAVE_ACTIVITY_RECORD",
  DELETE_ACTIVITY_RECORD = "DELETE_ACTIVITY_RECORD",
  SAVE_STUDENTS_ACTIVITY_SCORE = "SAVE_STUDENTS_ACTIVITY_SCORE",
  CALCULATE_QUARTERLY_ACTIVITIES_TOTAL = "CALCULATE_QUARTERLY_ACTIVITIES_TOTAL",
  CALCULATE_QUARTERLY_GRADE = "CALCULATE_QUARTERLY_GRADE";

const createNewClassStart = (newClass) => ({
  type: CREATE_CLASS_START,
  newClass,
});

const createNewClassSuccess = () => ({
  type: CREATE_CLASS_SUCCESS,
});

const createNewClassFail = (error) => ({
  type: CREATE_CLASS_FAIL,
  error: error,
});

export const createNewClass = ({
  subjectClass,
  edit,
  oldTeacher,
  oldClassroom,
  subjectClassId,
}) => async (dispatch, getState, { getFirebase, getFirestore }) => {
  dispatch(createNewClassStart(subjectClass.subject.label));
  try {
    const db = getFirestore();
    // First, check if subject class already exist for this section
    const subjectClassQuery = db
      .collection(CLASSES)
      .where("subject.value", "==", subjectClass.subject.value)
      .where("section", "==", subjectClass.section)
      .where("sy", "==", subjectClass.sy);
    const subjectClassQuerySnap = await subjectClassQuery.get();

    if (!edit && !subjectClassQuerySnap.empty) {
      throw new Error("query-error-subject");
    } else if (edit && !subjectClassQuerySnap.empty) {
      const docIds = map(subjectClassQuerySnap.docs, "id");
      if (!includes(docIds, subjectClassId)) {
        throw new Error("query-error-subject");
      }
    }

    const newClassRef = !edit
      ? db.collection(CLASSES).doc()
      : db.collection(CLASSES).doc(subjectClassId);
    if (!edit) {
      await newClassRef.set({
        ...subjectClass,
        createdOn: db.FieldValue.serverTimestamp(),
        lastUpdated: db.FieldValue.serverTimestamp(),
        deleted: false,
      });
    } else {
      await newClassRef.set(
        {
          ...subjectClass,
          lastUpdated: db.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
    }
    dispatch(createNewClassSuccess());

    if (!edit) {
      // Add enrolled students in section to class
      await addSectionStudentsToClass(
        newClassRef.id,
        subjectClass.section,
        subjectClass.sy
      );
      // Add subjectClass to subcollection "subjectClasses" of section doc
      await addClassToSection(
        subjectClass.section,
        subjectClass.sy,
        newClassRef.id
      );
    }

    if (subjectClass.teacher) {
      // Add subjectClass to subcollection "classes" in Employee/Teacher doc
      await addClassToTeacher(
        subjectClass.teacher.value,
        subjectClass.sy,
        newClassRef.id
      );
    }

    if (subjectClass.classroom) {
      // Add subjectClass to subcollection "subjectClass" of classroom doc
      await addClassToClassroom(
        subjectClass.classroom.value,
        subjectClass.sy,
        newClassRef.id
      );
    }

    // If editing
    if (edit) {
      // If old and new teachers are not equal...
      if (
        !isNil(subjectClass.teacher) &&
        !isNil(oldTeacher) &&
        !isEqual(subjectClass.teacher.value, oldTeacher.value)
      ) {
        // remove section from old adviser
        await removeClassToTeacher(
          oldTeacher.value,
          subjectClass.sy,
          newClassRef.id
        );
      }
      // If old and new classrooms are not equal...
      if (
        !isNil(subjectClass.classroom) &&
        !isNil(oldClassroom) &&
        !isEqual(subjectClass.classroom, oldClassroom)
      ) {
        // remove section from old classroom
        await removeClassToClassroom(
          oldClassroom.value,
          subjectClass.sy,
          newClassRef.id
        );
      }
    }

    const newClassDoc = await newClassRef.get();
    const newClass = { ...newClassDoc.data(), id: newClassDoc.id };
    dispatch(
      addActivityLog({
        doc: newClass,
        action: !edit ? "createNewClass" : "updateClass",
        actor: !edit ? subjectClass.createdBy : subjectClass.updatedBy,
      })
    );
  } catch (error) {
    let errorBody = error;
    if (error.message === "query-error-subject") {
      errorBody = {
        message: "Subject class already exist for this section.",
        field: "subject",
      };
    }
    dispatch(createNewClassFail(errorBody));
  }
};

const fetchSectionClassesStart = (section) => ({
  type: FETCH_SECTION_CLASSES_START,
  section,
});

const fetchSectionClassesSuccess = (section, classes) => ({
  type: FETCH_SECTION_CLASSES_SUCCESS,
  classes,
  section,
});

const fetchSectionClassesFail = (error) => ({
  type: FETCH_SECTION_CLASSES_SUCCESS,
  error,
});

export const fetchSectionClasses = ({ sectionClasses, section }) => async (
  dispatch
) => {
  dispatch(fetchSectionClassesStart(section));
  try {
    const fetchedClasses = await fetchClasses(sectionClasses);
    dispatch(fetchSectionClassesSuccess(section, fetchedClasses));
  } catch (error) {
    dispatch(fetchSectionClassesFail(error));
  }
};

const fetchStudentClassesStart = (student, sy) => ({
  type: FETCH_STUDENT_CLASSES_START,
  student,
  sy
});

const fetchStudentClassesSuccess = (student, classes, sy) => ({
  type: FETCH_STUDENT_CLASSES_SUCCESS,
  classes,
  student,
  sy
});

const fetchStudentClassesFail = (error) => ({
  type: FETCH_STUDENT_CLASSES_SUCCESS,
  error,
});

export const fetchStudentClasses = ({ studentClasses, student, sy }) => async (
  dispatch
) => {
  dispatch(fetchStudentClassesStart(student, sy));
  try {
    const fetchedClasses = await fetchClasses(studentClasses);
    const fetchedStudentRecords = await fetchStudentClassRecord(
      studentClasses,
      student
    );
    // Merge 2 arrays by class ID
    const studentClassesAndRecord = map(fetchedClasses, (subjectClass) =>
      assign(subjectClass, find(fetchedStudentRecords, ["id", subjectClass.id]))
    );
    dispatch(fetchStudentClassesSuccess(student, studentClassesAndRecord, sy));
  } catch (error) {
    dispatch(fetchStudentClassesFail(error));
  }
};

const fetchTeacherClassesStart = (teacher) => ({
  type: FETCH_TEACHER_CLASSES_START,
  teacher,
});

const fetchTeacherClassesSuccess = (teacher, classes) => ({
  type: FETCH_TEACHER_CLASSES_SUCCESS,
  classes,
  teacher,
});

const fetchTeacherClassesFail = (error) => ({
  type: FETCH_TEACHER_CLASSES_FAIL,
  error,
});

export const fetchTeacherClasses = ({ teacherClasses, teacher }) => async (
  dispatch
) => {
  dispatch(fetchTeacherClassesStart(teacher));
  try {
    const fetchedClasses = await fetchClasses(teacherClasses);
    dispatch(fetchTeacherClassesSuccess(teacher, fetchedClasses));
  } catch (error) {
    dispatch(fetchTeacherClassesFail(error));
  }
};

const inputStudentGradeStart = () => ({
  type: INPUT_STUDENT_GRADE_START,
});

const inputStudentGradeSuccess = () => ({
  type: INPUT_STUDENT_GRADE_SUCCESS,
});

const inputStudentGradeFail = (error) => ({
  type: INPUT_STUDENT_GRADE_FAIL,
  error,
});

export const inputStudentGrade = (studentGrade) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  dispatch(inputStudentGradeStart());
  try {
    const db = getFirestore();
    const classStudentRef = db
      .collection(CLASSES)
      .doc(studentGrade.classId)
      .collection(STUDENTS)
      .doc(studentGrade.studentId);
    const classStudentSnap = await classStudentRef.get();
    const studentClassRecord = classStudentSnap.data();
    // let studentGrades = studentClassRecord.grades
    let studentGrades = pickBy(
      {
        ...pick(studentClassRecord.grades, ["1Q", "2Q", "3Q", "4Q"]),
        [studentGrade.quarter]: studentGrade.quarterGrade,
      },
      identity
    );

    const numGradedQuarters = size(studentGrades);
    /**
     * If senior high - semester is equal to sem1 or sem2, numGradedQuarters SHOULD BE == 2
     * If NOT SHS - semester is blank, numGradedQuarters SHOULD BE == 4
     * ELSE - we don't calculate remarks and final grade
     *
     */
    let finalAndRemarks = {
      final: null,
      remarks: null,
    };
    if (
      (!isEmpty(studentGrade.semester) && numGradedQuarters === 2) ||
      (isEmpty(studentGrade.semester) && numGradedQuarters === 4)
    ) {
      const finalGrade = Math.round(
        sum(map(studentGrades, (grade) => grade)) / numGradedQuarters
      );
      finalAndRemarks.final = finalGrade;
      finalAndRemarks.remarks = finalGrade < 75 ? FAILED : PASSED;
    }

    await classStudentRef.set(
      {
        grades: {
          ...studentClassRecord.grades,
          ...studentGrades,
          ...finalAndRemarks,
        },
      },
      { merge: true }
    );
    
    dispatch(inputStudentGradeSuccess());
    
    // Add student grades in students -> classes
    const sy = getState().sy["current"];
    const studentClassesRef = db
        .collection(STUDENTS)
        .doc(studentGrade.studentId)
        .collection(CLASSES)
        .doc(sy);

    const studentClassUpdate = {
      ...studentClassRecord.grades,
      ...studentGrades,
      ...finalAndRemarks,
    };

    studentClassesRef.update(`grades.${studentGrade.classId}`, studentClassUpdate)
  } catch (error) {
    dispatch(inputStudentGradeFail(error));
  }
};

const createVideoMeetingStart = (roomName) => ({
  roomName,
  type: CREATE_VIDEO_MEETING_START,
});

const createVideoMeetingSuccess = () => ({
  type: CREATE_VIDEO_MEETING_SUCCESS,
});

const createVideoMeetingFail = (error) => ({
  type: CREATE_VIDEO_MEETING_FAIL,
  error,
});

export const createVideoMeeting = ({
  classId,
  videoMeetingID,
  roomName,
  edit,
  ...others
}) => async (dispatch, getState, { getFirestore }) => {
  dispatch(createVideoMeetingStart(roomName));
  try {
    const db = getFirestore();
    const videoMeetingRef = db
      .collection(CLASSES)
      .doc(classId)
      .collection(POSTS)
      .doc(videoMeetingID);
    if (edit) {
      await videoMeetingRef.set(
        {
          ...others,
          finished: db.FieldValue.serverTimestamp(),
          status: "finish",
        },
        { merge: true }
      );
    } else {
      await videoMeetingRef.set(
        {
          ...others,
          classId,
          roomName,
          createdOn: db.FieldValue.serverTimestamp(),
          started: db.FieldValue.serverTimestamp(),
          finished: null,
          deleted: false,
        },
        { merge: true }
      );
    }
    dispatch(createVideoMeetingSuccess());
  } catch (error) {
    dispatch(createVideoMeetingFail(error));
  }
};

export const saveGradeComponents = (
  gradeComponents,
  classId,
  toDelete = []
) => async (dispatch, getState, { getFirestore }) => {
  try {
    const db = getFirestore();
    const classActivityRecordRef = db
      .collection(CLASSES)
      .doc(classId)
      .collection(GRADE_COMPONENTS);
    const batchSaveGradeComponents = db.batch();

    gradeComponents.forEach((gc) => {
      const docRef = gc.id
        ? classActivityRecordRef.doc(gc.id)
        : classActivityRecordRef.doc();
      const docData = gc.id
        ? gc
        : { ...gc, createdOn: db.FieldValue.serverTimestamp(), deleted: false };
      batchSaveGradeComponents.set(docRef, docData, { merge: true });
    });

    toDelete.forEach((gc) => {
      batchSaveGradeComponents.update(classActivityRecordRef.doc(gc), {
        deleted: true,
        deletedOn: db.FieldValue.serverTimestamp(),
      });
    });

    await batchSaveGradeComponents.commit();
    dispatch({
      type: SAVE_GRADE_COMPONENTS,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveClassStudentScores = (
  record,
  classId,
  classActivityRecordId
) => async (dispatch, getState, { getFirestore }) => {
  try {
    const db = getFirestore();
    const classStudentRef = db
      .collection(CLASSES)
      .doc(classId)
      .collection(STUDENTS);

    const batchSaveGrades = db.batch();

    Object.keys(record).forEach((studentId) => {
      const score = record[studentId];
      batchSaveGrades.set(
        classStudentRef.doc(studentId),
        {
          classActivitiesScore: {
            [classActivityRecordId]: score,
          },
        },
        { merge: true }
      );
    });

    await batchSaveGrades.commit();
    dispatch({ type: SAVE_STUDENTS_ACTIVITY_SCORE });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveActivityRecord = (
  record,
  classId,
  classActivityRecordId
) => async (dispatch, getState, { getFirestore }) => {
  try {
    const db = getFirestore();
    const classActivityRecordRef = db
      .collection(CLASSES)
      .doc(classId)
      .collection(CLASS_ACTIVITIES);

    const docRef = classActivityRecordId
      ? classActivityRecordRef.doc(classActivityRecordId)
      : classActivityRecordRef.doc();

    const docData = classActivityRecordId
      ? record
      : {
          ...record,
          createdOn: db.FieldValue.serverTimestamp(),
          deleted: false,
        };
    await docRef.set(docData, { merge: true });

    dispatch({
      type: SAVE_ACTIVITY_RECORD,
    });

    const newClassActivityRecord = await docRef.get();
    const newClassActivityRecordId = newClassActivityRecord.id;
    const newClassActivity = newClassActivityRecord.data();

    // Save students scores
    await dispatch(
      saveClassStudentScores(record.scores, classId, newClassActivityRecordId)
    );

    // Calculate quarterly totals now
    await dispatch(
      calculateQuarterlyActivitiesTotal(
        newClassActivity.gcId,
        newClassActivity.qtr,
        classId
      )
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteClassActivityRecord = (
  classId,
  classActivityRecordId
) => async (dispatch, getState, { getFirestore }) => {
  try {
    const db = getFirestore();
    const classActivityRecordRef = db
      .collection(CLASSES)
      .doc(classId)
      .collection(CLASS_ACTIVITIES)
      .doc(classActivityRecordId);

    await classActivityRecordRef.set({ deleted: true }, { merge: true });

    const classActivitySnap = await classActivityRecordRef.get();
    const deletedClassActivityRecord = classActivitySnap.data();

    await dispatch(
      calculateQuarterlyActivitiesTotal(
        deletedClassActivityRecord.gcId,
        deletedClassActivityRecord.qtr,
        classId
      )
    );

    dispatch({
      type: SAVE_ACTIVITY_RECORD,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const calculateQuarterlyActivitiesTotal = (
  gcId,
  quarter,
  classId
) => async (dispatch, getState, { getFirestore }) => {
  try {
    const db = getFirestore();
    const classRef = db.collection(CLASSES).doc(classId);

    // Get all the students in the class
    const classSnapshot = await classRef.get();

    const currentClass = {
      id: classSnapshot.id,
      ...classSnapshot.data(),
    };

    // Get all the class activities of specific type in a quarter
    let allQuarterlyActivities = [];
    const classActivitiesByQuarterRef = classRef
      .collection(CLASS_ACTIVITIES)
      .where("gcId", "==", gcId)
      .where("qtr", "==", quarter)
      .where("deleted", "==", false);

    const activitiesByQuarterQuerySnapshot = await classActivitiesByQuarterRef.get();
    activitiesByQuarterQuerySnapshot.forEach((doc) => {
      allQuarterlyActivities.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    // Get combined scores in all activities
    const activitiesTotal =
      sum(map(allQuarterlyActivities, "highestPossibleScore")) || 0;
    const scores = map(allQuarterlyActivities, "scores");
    let combinedScores = {};

    scores.forEach((score) => {
      Object.keys(score).forEach((student) => {
        combinedScores = {
          ...combinedScores,
          [student]: (combinedScores[student] || 0) + score[student],
        };
      });
    });

    // Get HPS total of activities

    // Get Grade Component
    const gradeComponentSnap = await classRef
      .collection(GRADE_COMPONENTS)
      .doc(gcId)
      .get();
    const gradeComponent = {
      id: gradeComponentSnap.id,
      ...gradeComponentSnap.data(),
    };

    // Start a batched op
    const calculateQuarterlyTotalBatch = db.batch();
    const classStudentRef = classRef.collection(STUDENTS);

    // Iterate thru students
    currentClass.enrollees.forEach((student) => {
      let quarterTotal = {
        total: 0,
        ps: 0,
        ws: 0,
      };

      if (activitiesTotal > 0 && !isEmpty(scores)) {
        const studentTotal = combinedScores[student];
        const studentPS =
          activitiesTotal !== 0
            ? Math.round((studentTotal / activitiesTotal) * 100)
            : 0;
        const studentWS = Math.round(
          gradeComponent.percent * (studentPS / 100)
        );
        quarterTotal = {
          total: studentTotal,
          ps: studentPS,
          ws: studentWS,
        };
      }
      calculateQuarterlyTotalBatch.set(
        classStudentRef.doc(student),
        {
          quarterlyActivitiesTotal: {
            [gcId]: {
              [quarter]: quarterTotal,
            },
          },
        },
        { merge: true }
      );
    });

    // Perform batch updates
    await calculateQuarterlyTotalBatch.commit();
    dispatch({ type: CALCULATE_QUARTERLY_ACTIVITIES_TOTAL });

    // Perform calculate quarterly grade
    dispatch(
      calculateQuarterGrade(
        classId,
        quarter,
        currentClass.enrollees,
        currentClass.semester
      )
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const calculateQuarterGrade = (
  classId,
  qtr,
  students,
  semester
) => async (dispatch, getState, { getFirestore }) => {
  const sy = getState().sy["current"];
  try {
    const db = getFirestore();
    const classRef = db.collection(CLASSES).doc(classId);
    const calculateQuarterGradeBatch = db.batch();

    // Get all Grade Components for this class
    const gradeComponentsRef = classRef
      .collection(GRADE_COMPONENTS)
      .where("deleted", "==", false);
    const gcSnap = await gradeComponentsRef.get();
    const gradeComponents = [];
    gcSnap.forEach((doc) => gradeComponents.push(doc.id));

    // Iterate on each Grade Component then iterate to each student to get their "quarterlyActivitiesTotal" of the quarter
    await asyncForEach(students, async (studentId) => {
      const classStudentRef = classRef.collection(STUDENTS).doc(studentId);
      const studentSnap = await classStudentRef.get();
      const student = studentSnap.data();
      let quarterlyGrade = (student.quarterlyGrades &&
        student.quarterlyGrades[qtr]) || {
        rawPercentile: 0,
        grade: 60 + 0 / 4,
      };

      if (student["quarterlyActivitiesTotal"]) {
        const activitiesTotal = student["quarterlyActivitiesTotal"];
        let rp = 0,
          grade = 0;
        gradeComponents.forEach((gcId) => {
          if (activitiesTotal[gcId] && activitiesTotal[gcId][qtr]) {
            const quarterlyGCTotal = activitiesTotal[gcId][qtr];
            rp += quarterlyGCTotal.ws;
          }
        });
        // Get Quarterly Grade
        if (rp < 60) {
          grade = Math.round(60 + rp / 4);
        } else {
          grade = Math.round(75 + (rp - 60) / 1.6);
        }

        quarterlyGrade = {
          rawPercentile: rp,
          grade,
        };
      }

      // Attempt calculate final grade and remarks
      let finalAndRemarks = {
        final: null,
        remarks: null,
      };

      const studentQuarterlyGrades = pickBy(
        {
          ...pick(student.grades, ["1Q", "2Q", "3Q", "4Q"]),
          [qtr]: quarterlyGrade.grade,
        },
        identity
      );

      const numGradedQuarters = size(studentQuarterlyGrades);

      if (
        (!isEmpty(semester) && numGradedQuarters === 2) ||
        (isEmpty(semester) && numGradedQuarters === 4)
      ) {
        const finalGrade = Math.round(
          sum(map(studentQuarterlyGrades, (grade) => grade)) / numGradedQuarters
        );
        finalAndRemarks.final = finalGrade;
        finalAndRemarks.remarks = finalGrade < 75 ? FAILED : PASSED;
      }

      // Execute database update
      calculateQuarterGradeBatch.set(
        classStudentRef,
        {
          quarterlyGrades: {
            [qtr]: quarterlyGrade,
          },
          grades: {
            ...studentQuarterlyGrades,
            [qtr]: quarterlyGrade.grade,
            ...finalAndRemarks,
          },
        },
        { merge: true }
      );

      // Add grades entry to student's collection
      const studentClassesRef = db
        .collection(STUDENTS)
        .doc(studentId)
        .collection(CLASSES)
        .doc(sy);

      let studentClassUpdate = {};
      studentClassUpdate[`grades.${classId}`] = {
        ...studentQuarterlyGrades,
        [qtr]: quarterlyGrade.grade,
        ...finalAndRemarks,
      };

      calculateQuarterGradeBatch.update(studentClassesRef, studentClassUpdate);
    });

    await calculateQuarterGradeBatch.commit();
    dispatch({
      type: CALCULATE_QUARTERLY_GRADE,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
