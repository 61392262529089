import { addActivityLog } from '../logs/actions';
import { BUILDINGS } from '../../constants/collections';

export const CREATE_SCHOOL_BUILDING_START = 'CREATE_SCHOOL_BUILDING_START',
	CREATE_SCHOOL_BUILDING_SUCCESS = 'CREATE_SCHOOL_BUILDING_SUCCESS',
	CREATE_SCHOOL_BUILDING_FAIL = 'CREATE_SCHOOL_BUILDING_FAIL';

const createNewSchoolBuildingStart = (newBuilding) => ({
	type: CREATE_SCHOOL_BUILDING_START,
	newBuilding
});

const createNewSchoolBuildingSuccess = () => ({
	type: CREATE_SCHOOL_BUILDING_SUCCESS
});

const createNewSchoolBuildingFail = (error) => ({
	type: CREATE_SCHOOL_BUILDING_FAIL,
	error: error
});

export const createNewSchoolBuilding = (building) => async (dispatch, getState, { getFirestore }) => {
	dispatch(createNewSchoolBuildingStart(building.name));
	try {
		const firestore = getFirestore();
		const newBuildingRef = firestore.collection(BUILDINGS).doc();
		await newBuildingRef.set({
			...building,
			createdOn: firestore.FieldValue.serverTimestamp(),
			lastUpdated: firestore.FieldValue.serverTimestamp(),
			deleted: false
		});
		dispatch(createNewSchoolBuildingSuccess());
		const newBuildingDoc = await newBuildingRef.get();
		const newBuilding = { ...newBuildingDoc.data(), id: newBuildingDoc.id };
		dispatch(
			addActivityLog({
				doc: newBuilding,
				action: 'createNewSchoolBuilding',
				actor: building.createdBy
			})
		);
	} catch (error) {
		console.log(error);
		dispatch(createNewSchoolBuildingFail(error));
	}
};
