import { addActivityLog } from '../logs/actions';
import { EMPLOYEES, IDX_EMPLOYEES } from '../../constants/collections';
import { addSchoolToEmployee, searchEmployee } from '../../helpers/employees';
import { addEmployeeToSchool } from '../../helpers/schools';
import map from 'lodash/map';
import includes from 'lodash/includes';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START',
	CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS',
	CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL',
	QUERY_NEW_EMPLOYEE_START = 'QUERY_NEW_EMPLOYEE_START',
	QUERY_NEW_EMPLOYEE_SUCCESS = 'QUERY_NEW_EMPLOYEE_SUCCESS',
	QUERY_NEW_EMPLOYEE_FAIL = 'QUERY_NEW_EMPLOYEE_FAIL',
	ADD_EXISTING_EMPLOYEES = "ADD_EXISTING_EMPLOYEES";

const createNewEmployeeStart = (newEmployee) => ({
	type: CREATE_EMPLOYEE_START,
	newEmployee
});

const createNewEmployeeSuccess = () => ({
	type: CREATE_EMPLOYEE_SUCCESS
});

const createNewEmployeeFail = (error) => ({
	type: CREATE_EMPLOYEE_FAIL,
	error: error
});

export const createNewEmployee = ({ employee, school, sy, employeeId, edit }) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	dispatch(
		createNewEmployeeStart([employee.firstName, employee.middleName, employee.lastName, employee.extName].join(' '))
	);
	try {
		const db = getFirestore();
		const newEmployeeRef = !edit ? db.collection(EMPLOYEES).doc() : db.collection(EMPLOYEES).doc(employeeId);
		if (!edit) {
			await newEmployeeRef.set({
				...employee,
				createdOn: db.FieldValue.serverTimestamp(),
				lastUpdated: db.FieldValue.serverTimestamp(),
				deleted: false
			});
		} else {
			await newEmployeeRef.set(
				{
					...employee,
					lastUpdated: db.FieldValue.serverTimestamp()
				},
				{ merge: true }
			);
		}

		dispatch(createNewEmployeeSuccess());

		if (!edit && school) {
			// Add document to subcollection "schools" if schoolID is present
			await addSchoolToEmployee(newEmployeeRef.id, school, sy);
			await addEmployeeToSchool(school, newEmployeeRef.id, sy, employee.teaching);
		}

		const newEmployeeDoc = await newEmployeeRef.get();
		const newEmployee = { ...newEmployeeDoc.data(), id: newEmployeeDoc.id };
		dispatch(
			addActivityLog({
				doc: newEmployee,
				action: !edit ? 'createNewEmployee' : 'updateEmployee',
				actor: !edit ? employee.createdBy : employee.updatedBy
			})
		);
	} catch (error) {
		dispatch(createNewEmployeeFail(error));
	}
};

const queryNewEmployeeStart = () => ({
	type: QUERY_NEW_EMPLOYEE_START
});

const queryNewEmployeeSuccess = () => ({
	type: QUERY_NEW_EMPLOYEE_SUCCESS
});

const queryNewEmployeeFail = (error) => ({
	type: QUERY_NEW_EMPLOYEE_FAIL,
	error
});

export const queryNewEmployee = ({ employee, employeeId, edit }) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	dispatch(queryNewEmployeeStart());
	try {
		const db = getFirestore();
		const employeeRef = db.collection(IDX_EMPLOYEES);
		const employeeNameQuery = employeeRef.where(
			'keywords',
			'array-contains',
			`${employee.firstName.toLowerCase()} ${employee.middleName.toLowerCase()} ${employee.lastName.toLowerCase()}&all&all`
		);
		const employeeNameSnap = await employeeNameQuery.get();

		if (!edit && !employeeNameSnap.empty) {
			throw new Error('query-error-fullname');
		} else if (edit && !employeeNameSnap.empty) {
			const docIds = map(employeeNameSnap.docs, 'id');
			if (!includes(docIds, employeeId)) {
				throw new Error('query-error-fullname');
			}
		}
		// if (!employeeNameSnap.empty) {
		// 	throw new Error('query-error-fullname');
		// }
		dispatch(queryNewEmployeeSuccess());
	} catch (error) {
		console.log(error);
		let errorBody = error;
		if (error.message === 'query-error-fullname') {
			errorBody = {
				message: "That employee's name is already taken.",
				field: 'fullname'
			};
		}
		dispatch(queryNewEmployeeFail(errorBody));
	}
};

export const addExistingEmployees = ({
	teaching,
	employees,
	schoolId,
	sy, 
	method,
}) => async (dispatch, getState, {getFirestore}) => {
	try {
		// const db = getFirestore();
		async function processAdding(employeeId){
			// Add document to subcollection "schools" if schoolID is present
			await addSchoolToEmployee(employeeId, schoolId, sy);
			await addEmployeeToSchool(schoolId, employeeId, sy, teaching);
		}
		if (method === "fromSY") {
			// employees here is the SY
			const querySnap = await searchEmployee(teaching, employees, schoolId, "")
			console.log(querySnap)
			if (!querySnap.empty) {
				querySnap.forEach(doc => processAdding(doc.id))
			}
		} else {
			// employees here is array of value-label collection
			employees.forEach(employees => {
				processAdding(employees.value)
			})
		}
		dispatch({type: ADD_EXISTING_EMPLOYEES})
	} catch (error) {
		throw error
	}
}