import { LOGS } from '../../constants/collections'

const createActivityLog = ({doc, action, actor}) => {
    let message = ''
    switch (action) {
        case 'createNewSchool':
            message = "New school created."
            break;

        case 'createNewGradeLevel':
            message = "New grade level created."
            break;

        case 'createNewSection':
            message = "New section created."
            break;

        case 'createNewEmployee':
            message = "New employee created."
            break;

        default:
            break;
    }
    return {
        action,
        document: doc,
        message,
        actor
    }
}

export const addActivityLog = activityLog => 
    async (dispatch, getState, {getFirestore}) => {
        let log = createActivityLog(activityLog)
        try {
            const db = getFirestore();
            const logRef = db.collection(LOGS).doc()
            await logRef.set({
                ...log,
                createdOn: db.FieldValue.serverTimestamp(),
            })
            dispatch({
                type: 'ADD_LOG_SUCCESS',
                data: log
            })
        } catch (error) {
            dispatch({
                type: 'ADD_LOG_FAIL',
                data: log
            })
        }
    }