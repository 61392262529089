import firebase from "../constants/firebase";
import {
  SCHOOLS,
  STUDENTS,
  SECTIONS,
  CLASSES,
  ATTENDANCE,
  IDX_STUDENTS,
} from "../constants/collections";
import forEach from "lodash/forEach";
import countBy from "lodash/countBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { asyncForEach } from "./Utils";
import { addStudentToClass } from "./classes";

export const addSchoolToStudent = async (student, school, sy) => {
  const db = firebase.firestore();
  const studentRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(SCHOOLS)
    .doc(sy);
  return await studentRef.set(
    {
      schools: firebase.firestore.FieldValue.arrayUnion(school),
    },
    { merge: true }
  );
};

export const addSectionToStudent = async (
  student,
  section,
  sy,
  unenroll = false
) => {
  const db = firebase.firestore();
  const studentRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(SECTIONS)
    .doc(sy);
  return await studentRef.set(
    {
      [SECTIONS]: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(section)
        : firebase.firestore.FieldValue.arrayRemove(section),
    },
    { merge: true }
  );
};

export const addClassToStudent = async (
  student,
  classId,
  sy,
  unenroll = false
) => {
  const db = firebase.firestore();
  const studentRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(CLASSES)
    .doc(sy);
  return await studentRef.set(
    {
      [CLASSES]: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(classId)
        : firebase.firestore.FieldValue.arrayRemove(classId),
    },
    { merge: true }
  );
};

export const setSectionClassPopulation = (students) => {
  let total = 0;
  let population = { popTotal: 0, popMale: 0, popFemale: 0 };
  forEach(countBy(students, "gender"), (count, key) => {
    total += count;
    const newKey = `pop${key}`;
    population = {
      ...population,
      [newKey]: count,
      popTotal: total,
    };
  });

  return population;
};

export const addSectionClassesToStudent = async (
  student,
  section,
  sy,
  unenroll = false
) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(section)
    .collection(CLASSES)
    .doc(sy);
  const sectionClassesSnap = await sectionRef.get();

  if (!sectionClassesSnap.exists) return;

  const sectionClasses = sectionClassesSnap.data();

  if (isNil(sectionClasses[CLASSES]) || isEmpty(sectionClasses[CLASSES]))
    return;

  const studentRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(CLASSES)
    .doc(sy);
  await studentRef.set(
    {
      [CLASSES]: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(...sectionClasses[CLASSES])
        : firebase.firestore.FieldValue.arrayRemove(...sectionClasses[CLASSES]),
    },
    { merge: true }
  );
  // Add student to each subject class
  return await asyncForEach(sectionClasses[CLASSES], async (classId) => {
    await addStudentToClass(classId, student, sy, unenroll);
  });
};

export const addAttendanceToStudent = async (
  student,
  attendanceId,
  attendanceData
) => {
  const db = firebase.firestore();
  const studentAttendanceRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(ATTENDANCE)
    .doc(attendanceId);
  return await studentAttendanceRef.set({ ...attendanceData }, { merge: true });
};

export const searchStudent = async ({
  sy,
  schoolId,
  search,
  order = "birthdate",
  limit = 0,
}) => {
  try {
    const db = firebase.firestore();
    const keywords = [search.toLowerCase(), sy, schoolId].join("&");
    let studentRef = db
      .collection(IDX_STUDENTS)
      .where("keywords", "array-contains", keywords)
      .orderBy(order, "desc");
    if (limit) {
      studentRef.limit(limit);
    }
    return await studentRef.get();
  } catch (error) {
    throw error;
  }
};
