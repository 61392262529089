import firebase from '../constants/firebase';
import { USERS } from '../constants/collections';

export const setUserProfile = async (user, registration, unAuthorized = false) => {
	const db = firebase.firestore();
	const userRef = db.collection(USERS).doc(user.user.uid);
	let userData = {
		displayName: user.user.displayName,
		photoURL: user.user.photoURL,
		email: user.user.email,
		createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		roles: !unAuthorized ? firebase.firestore.FieldValue.arrayUnion(registration.role.value) : null,
		status: !unAuthorized ? 'enabled' : 'disabled',
		schools: registration ? registration.schools : [],
		profileType: registration && registration.profileType,
		profileId: registration && registration.profileId
	};
	return await userRef.set(userData, { merge: true });
};

export const getAppUserToken = async () => {
	const accessToken = await firebase.auth().currentUser.getIdToken();
	const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
	return `${projectId} ${accessToken}`;
};
