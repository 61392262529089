import {
	CREATE_SCHOOL_START,
	CREATE_SCHOOL_FAIL,
	CREATE_SCHOOL_SUCCESS,
	QUERY_NEW_SCHOOL_START,
	QUERY_NEW_SCHOOL_SUCCESS,
	QUERY_NEW_SCHOOL_FAIL,
	FORM137_SELECT_STUDENT
} from './actions';

const initState = {
	creating: false,
	created: false,
	createError: null,
	newSchoolName: '',
	queryingNew: false,
	queriedNew: false,
	queryNewError: null,
	form137Student: null,
};

const schoolReducer = (state = initState, action) => {
	switch (action.type) {
		case QUERY_NEW_SCHOOL_START:
			return {
				...state,
				queryingNew: true,
				queriedNew: false,
				queryNewError: null
			};

		case QUERY_NEW_SCHOOL_SUCCESS:
			return {
				...state,
				queryingNew: false,
				queriedNew: true,
				queryNewError: null
			};

		case QUERY_NEW_SCHOOL_FAIL:
			return {
				...state,
				queryingNew: false,
				queriedNew: false,
				queryNewError: action.error
			};
		case CREATE_SCHOOL_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newSchoolName: action.newSchool
			};
		case CREATE_SCHOOL_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_SCHOOL_FAIL:
			return {
				creating: false,
				created: false,
				createError: action.error.message
			};
		case FORM137_SELECT_STUDENT:
			return {
				...state,
				form137Student: action.student
			};
		default:
			return {
				...state
			};
	}
};

export default schoolReducer;
