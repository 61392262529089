import { ADD_USER_ACCOUNT_START, ADD_USER_ACCOUNT_SUCCESS, ADD_USER_ACCOUNT_FAIL } from './actions';

const initState = {
	addingUser: false,
	addedUser: false,
	addUserError: null,
	addUserData: null
};

const userAccountReducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_USER_ACCOUNT_START:
			return {
				...state,
				addingUser: true,
				addedUser: false,
				addUserData: action.profile
			};
		case ADD_USER_ACCOUNT_SUCCESS:
			return {
				...state,
				addingUser: false,
				addedUser: true
			};
		case ADD_USER_ACCOUNT_FAIL:
			return {
				...state,
				addingUser: false,
				addedUser: false,
				addUserError: action.error.message
			};
		default:
			return state;
	}
};

export default userAccountReducer;
