export const SCHOOLS = "schools",
  LOGS = "logs",
  GRADE_LEVELS = "gradeLevels",
  SECTIONS = "sections",
  EMPLOYEES = "employees",
  STUDENTS = "students",
  TEACHERS = "teachers",
  STAFFS = "staffs",
  IDX_STUDENTS = "students_index",
  IDX_EMPLOYEES = "employees_index",
  IDX_SECTIONS = "sections_index",
  IDX_SCHOOLS = "schools_index",
  ADVISERS = "advisers",
  ADVISORIES = "advisories",
  BUILDINGS = "buildings",
  CLASSROOMS = "classrooms",
  CLASSES = "classes",
  ATTENDANCE = "attendance",
  USER_REGISTRATIONS = "user_registrations",
  USER_ACCOUNTS = "user_accounts",
  USERS = "users",
  POSTS = "posts",
  VIDEO_MEETINGS = "video_meetings",
  NOTIFICATIONS = "notifications",
  SBM_DIRECTORIES = "sbm_directories",
  SBM_CONTENTS = "sbm_contents",
  GRADE_COMPONENTS = "grade_components",
  ASSESSMENTS = "assessments",
  CLASS_ACTIVITIES = "class_activities",
  MASTER_GRADING_SHEETS = "master_grading_sheets",
  VALUES = "values",
  ABSENCES = "absences";
