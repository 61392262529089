import {
    CREATE_GRADE_LEVEL_START,
    CREATE_GRADE_LEVEL_FAIL,
    CREATE_GRADE_LEVEL_SUCCESS
} from './actions'

const initState = {
    creating: false,
    created: false,
    createError: null,
    newGradeLevelName: ''
}

const gradeLevelReducer = ( state = initState, action ) => {
    switch (action.type) {
        case CREATE_GRADE_LEVEL_START:
            return {
                ...state,
                creating: true,
                created: false,
                createError: null,
                newGradeLevelName: action.newGradeLevel
            }
        case CREATE_GRADE_LEVEL_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
                createError: null
            }
        case CREATE_GRADE_LEVEL_FAIL:
            return {
                creating: false,
                created: false,
                createError: action.error.message
            }
        default:
            return {
                ...state
            }
    }
}

export default gradeLevelReducer;