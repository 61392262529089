import { EMPLOYEES } from "../../constants/collections";
import { asyncForEach } from "../../helpers/Utils";
import * as employeesServices from "../../helpers/employees";

export const FETCH_SCHOOL_TEACHERS_START = "FETCH_SCHOOL_TEACHERS_START",
  FETCH_SCHOOL_TEACHERS_FAIL = "FETCH_SCHOOL_TEACHERS_FAIL",
  FETCH_SCHOOL_TEACHERS_SUCCESS = "FETCH_SCHOOL_TEACHERS_SUCCESS",
  FETCH_SCHOOL_TEACHERS = "FETCH_SCHOOL_TEACHERS";

const fetchSchoolTeachersStart = (school) => ({
  type: FETCH_SCHOOL_TEACHERS_START,
  school,
});

const fetchSchoolTeachersSuccess = (school, teachers) => ({
  type: FETCH_SCHOOL_TEACHERS_SUCCESS,
  teachers,
  school,
});

const fetchSchoolTeachersFail = (error) => ({
  type: FETCH_SCHOOL_TEACHERS_FAIL,
  error,
});

export const fetchSchoolTeachers =
  ({ school, teachers }) =>
  async (dispatch, getState, { getFirestore }) => {
    dispatch(fetchSchoolTeachersStart(school));
    try {
      const db = getFirestore();
      const fetchedTeachers = [];
      await asyncForEach(teachers, async (teacherId) => {
        const teacherRef = db.collection(EMPLOYEES).doc(teacherId);
        const teacherDoc = await teacherRef.get();
        if (teacherDoc.exists) {
          const teacher = { id: teacherDoc.id, ...teacherDoc.data() };
          fetchedTeachers.push(teacher);
        }
      });
      dispatch(fetchSchoolTeachersSuccess(school, fetchedTeachers));
    } catch (error) {
      dispatch(fetchSchoolTeachersFail(error));
    }
  };

export const fetchTeachers = ({newSearch, ...rest}) => async (dispatch, getState) => {
  try {
    const params = {
      ...rest,
      limit: newSearch ? 16 : getState().teacher.listSize,
      teaching: true,
    };
    const data = await employeesServices.fetchEmployees(params);
    dispatch({
      type: FETCH_SCHOOL_TEACHERS,
      teachers: data.employees,
      count: data.count,
    });
  } catch (error) {
    throw error.message;
  }
};
