import { CREATE_SCHOOL_BUILDING_START, CREATE_SCHOOL_BUILDING_FAIL, CREATE_SCHOOL_BUILDING_SUCCESS } from './actions';

const initState = {
	creating: false,
	created: false,
	createError: null,
	newBuildingName: ''
};

const schoolBuildingReducer = (state = initState, action) => {
	switch (action.type) {
		case CREATE_SCHOOL_BUILDING_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newBuildingName: action.newBuilding
			};
		case CREATE_SCHOOL_BUILDING_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_SCHOOL_BUILDING_FAIL:
			return {
				creating: false,
				created: false,
				createError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default schoolBuildingReducer;
