export const schoolLevels = [
  { value: "kndrgtn", label: "Kindergaten School", sort: 1, alt: "K" },
  { value: "elementary", label: "Elementary School", sort: 2, alt: "ES" },
  { value: "juniorHigh", label: "Junior High School", sort: 3, alt: "JHS" },
  { value: "seniorHigh", label: "Senior High School", sort: 4, alt: "SHS" },
];

export const schoolType = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];

export const classroomCapacity = [
  { value: "0-25", label: "0-25" },
  { value: "25-50", label: "25-50" },
  { value: "50-75", label: "50-75" },
  { value: "75-100", label: "75-100" },
  { value: "100+", label: "100+" },
];

export const schoolYears = [
  { value: "20162017", label: "2016-2017", startYr: "2016", endYr: "2017" },
  { value: "20172018", label: "2017-2018", startYr: "2017", endYr: "2018" },
  { value: "20182019", label: "2018-2019", startYr: "2018", endYr: "2019" },
  { value: "20192020", label: "2019-2020", startYr: "2019", endYr: "2020" },
  { value: "20202021", label: "2020-2021", startYr: "2020", endYr: "2021" },
  { value: "20212022", label: "2021-2022", startYr: "2021", endYr: "2022" },
  { value: "20222023", label: "2022-2023", startYr: "2022", endYr: "2023" },
  { value: "20232024", label: "2023-2024", startYr: "2023", endYr: "2024" },
  { value: "20242025", label: "2024-2025", startYr: "2024", endYr: "2025" },
  { value: "20252026", label: "2025-2026", startYr: "2025", endYr: "2026" },
];

export const syMonths = [
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
];
