import { combineReducers } from "redux";
import settingsReducer from "./settings/reducer";
import menuReducer from "./menu/reducer";
import authReducer from "./auth/reducer";
import schoolReducer from "./school/reducer";
import gradeLevelReducer from "./gradeLevel/reducer";
import sectionReducer from "./section/reducer";
import employeeReducer from "./employee/reducer";
import searchReducer from "./search/reducer";
import studentReducer from "./student/reducer";
import syReducer from "./sy/reducer";
import classroomReducer from "./classroom/reducer";
import schoolBuildingReducer from "./schoolBuilding/reducer";
import classReducer from "./class/reducer";
import userAccountReducer from "./userAccounts/reducer";
import attendanceReducer from "./attendance/reducer";
import sbmReducer from "./sbm/reducer";
import teacherReducer from "./teacher/reducer";
import dashboardReducer from "./dashboard/reducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  menu: menuReducer,
  auth: authReducer,
  school: schoolReducer,
  gradeLevel: gradeLevelReducer,
  section: sectionReducer,
  employee: employeeReducer,
  student: studentReducer,
  sy: syReducer,
  schoolBuilding: schoolBuildingReducer,
  classroom: classroomReducer,
  subjectClass: classReducer,
  search: searchReducer,
  attendance: attendanceReducer,
  userAccount: userAccountReducer,
  sbm: sbmReducer,
  teacher: teacherReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
