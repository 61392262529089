import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_START,
} from "../actions";

const INIT_STATE = {
  loading: false,
  error: "",
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_START:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REGISTER_USER_START:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default authReducer;
