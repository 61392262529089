import { addActivityLog } from '../logs/actions';
import { SCHOOLS } from '../../constants/collections';
import map from 'lodash/map';
import includes from 'lodash/includes';

export const CREATE_SCHOOL_START = 'CREATE_SCHOOL_START',
	CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS',
	CREATE_SCHOOL_FAIL = 'CREATE_SCHOOL_FAIL',
	QUERY_NEW_SCHOOL_START = 'QUERY_NEW_SCHOOL_START',
	QUERY_NEW_SCHOOL_SUCCESS = 'QUERY_NEW_SCHOOL_SUCCESS',
	QUERY_NEW_SCHOOL_FAIL = 'QUERY_NEW_SCHOOL_FAIL',
	FORM137_SELECT_STUDENT = "FORM137_SELECT_STUDENT";

const queryNewSchoolStart = () => ({
	type: QUERY_NEW_SCHOOL_START
});

const queryNewSchoolSuccess = () => ({
	type: QUERY_NEW_SCHOOL_SUCCESS
});

const queryNewSchoolFail = (error) => ({
	type: QUERY_NEW_SCHOOL_FAIL,
	error
});

export const queryNewSchool = ({ school, schoolId, edit }) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	dispatch(queryNewSchoolStart());
	try {
		const db = getFirestore();
		const schoolRef = db.collection(SCHOOLS);
		const schoolIdQuery = schoolRef.where('schoolId', '==', school.schoolId);
		const schoolIdSnap = await schoolIdQuery.get();
		// if (!schoolIdSnap.empty) {
		// 	throw new Error('query-error-schoolId');
		// }
		if (!edit && !schoolIdSnap.empty) {
			throw new Error('query-error-schoolId');
		} else if (edit && !schoolIdSnap.empty) {
			const docIds = map(schoolIdSnap.docs, 'id');
			if (!includes(docIds, schoolId)) {
				throw new Error('query-error-schoolId');
			}
		}

		const schoolNameQuery = schoolRef.where('name', '==', school.name);
		const schoolNameSnap = await schoolNameQuery.get();
		// if (!schoolNameSnap.empty) {
		// 	throw new Error('query-error-schoolName');
		// }
		if (!edit && !schoolNameSnap.empty) {
			throw new Error('query-error-schoolName');
		} else if (edit && !schoolNameSnap.empty) {
			const docIds = map(schoolNameSnap.docs, 'id');
			if (!includes(docIds, schoolId)) {
				throw new Error('query-error-schoolName');
			}
		}
		dispatch(queryNewSchoolSuccess());
	} catch (error) {
		console.log(error);
		let errorBody = error;
		if (error.message === 'query-error-schoolId') {
			errorBody = {
				message: 'That school ID is already taken.',
				field: 'schoolId'
			};
		} else if (error.message === 'query-error-schoolName') {
			errorBody = {
				message: 'That school name is already taken.',
				field: 'name'
			};
		}
		dispatch(queryNewSchoolFail(errorBody));
	}
};

const createNewSchoolStart = (newSchool) => ({
	type: CREATE_SCHOOL_START,
	newSchool
});

const createNewSchoolSuccess = () => ({
	type: CREATE_SCHOOL_SUCCESS
});

const createNewSchoolFail = (error) => ({
	type: CREATE_SCHOOL_FAIL,
	error: error
});

export const createNewSchool = ({ school, schoolId, edit }) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	dispatch(createNewSchoolStart(school.name));
	try {
		const db = getFirestore();
		const newSchoolRef = !edit ? db.collection(SCHOOLS).doc() : db.collection(SCHOOLS).doc(schoolId);
		if (!edit) {
			await newSchoolRef.set({
				...school,
				createdOn: db.FieldValue.serverTimestamp(),
				lastUpdated: db.FieldValue.serverTimestamp(),
				deleted: false
			});
		} else {
			await newSchoolRef.set(
				{
					...school,
					lastUpdated: db.FieldValue.serverTimestamp()
				},
				{ merge: true }
			);
		}

		dispatch(createNewSchoolSuccess());
		const newSchoolDoc = await newSchoolRef.get();
		const newSchool = { ...newSchoolDoc.data(), id: newSchoolDoc.id };
		dispatch(
			addActivityLog({
				doc: newSchool,
				action: !edit ? 'createNewSchool' : 'updatedSchool',
				actor: !edit ? school.createdBy : school.updatedBy
			})
		);
	} catch (error) {
		dispatch(createNewSchoolFail(error));
	}
};
