import firebase from '../constants/firebase';
import { USER_REGISTRATIONS, EMPLOYEES, STUDENTS, USER_ACCOUNTS, USERS } from '../constants/collections';
import { SCHOOL_ADMIN } from '../constants/userAccounts';

export const setUserRegistrationCompleted = async (regId) => {
	const db = firebase.firestore();
	const userRegRef = db.collection(USER_REGISTRATIONS).doc(regId);
	return await userRegRef.set(
		{
			completed: true,
			lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
		},
		{ merge: true }
	);
};

export const setProfileUserRegistrationEnabled = async (registration, userId) => {
	const db = firebase.firestore();
	const COL = registration.profileType === 'employee' ? EMPLOYEES : STUDENTS;
	const profileUserRegRef = db
		.collection(COL)
		.doc(registration.profileId)
		.collection(USER_ACCOUNTS)
		.doc(registration.role.value);
	return await profileUserRegRef.set(
		{
			status: 'enabled',
			uid: userId
		},
		{ merge: true }
	);
};

export const getUserAccount = async (profileId, type) => {
	const db = firebase.firestore();
	const COL = type === 'employee' ? EMPLOYEES : STUDENTS;
	const profileUserRegRef = db.collection(COL).doc(profileId).collection(USER_ACCOUNTS).doc(type);
	const userAcctSnap = await profileUserRegRef.get();
	return userAcctSnap.data();
};

export const makeSchoolAdmin = async (userId) => {
	const db = firebase.firestore();
	const usersRef = db.collection(USERS).doc(userId);
	return await usersRef.set(
		{
			roles: firebase.firestore.FieldValue.arrayUnion(SCHOOL_ADMIN)
		},
		{ merge: true }
	);
};
