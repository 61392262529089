import {
	QUERY_NEW_STUDENT_START,
	QUERY_NEW_STUDENT_SUCCESS,
	QUERY_NEW_STUDENT_FAIL,
	CREATE_STUDENT_START,
	CREATE_STUDENT_SUCCESS,
	CREATE_STUDENT_FAIL,
	FETCH_SECTION_STUDENTS_START,
	FETCH_SECTION_STUDENTS_SUCCESS,
	FETCH_SECTION_STUDENTS_FAIL,
	EDIT_STUDENT,
	FETCH_CLASS_STUDENTS_START,
	FETCH_CLASS_STUDENTS_SUCCESS,
	FETCH_CLASS_STUDENTS_FAIL
} from './actions';

const initState = {
	queryingNew: false,
	queriedNew: false,
	queryNewError: null,
	creating: false,
	created: false,
	createError: null,
	newStudentName: '',
	fetching: false,
	fetched: false,
	fetchError: null,
	edit: null
};

const populationStatDefault = { popTotal: null, popMale: null, popFemale: null };

const studentReducer = (state = initState, action) => {
	switch (action.type) {
		case QUERY_NEW_STUDENT_START:
			return {
				...state,
				queryingNew: true,
				queriedNew: false,
				queryNewError: null
			};

		case QUERY_NEW_STUDENT_SUCCESS:
			return {
				...state,
				queryingNew: false,
				queriedNew: true,
				queryNewError: null
			};

		case QUERY_NEW_STUDENT_FAIL:
			return {
				...state,
				queryingNew: false,
				queriedNew: false,
				queryNewError: action.error
			};
		case CREATE_STUDENT_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newStudentName: action.newStudent
			};
		case CREATE_STUDENT_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_STUDENT_FAIL:
			return {
				creating: false,
				created: false,
				createError: action.error.message
			};
		case FETCH_SECTION_STUDENTS_START:
			return {
				...state,
				fetching: true,
				fetched: false,
				fetchError: null,
				[`sectionStudents_${action.section}`]: state[`sectionStudents_${action.section}`] || [],
				[`sectionPopulationStat_${action.section}`]:
					state[`sectionPopulationStat_${action.section}`] || populationStatDefault
			};
		case FETCH_SECTION_STUDENTS_SUCCESS:
			return {
				...state,
				fetching: false,
				fetched: true,
				[`sectionStudents_${action.section}`]: action.students,
				[`sectionPopulationStat_${action.section}`]: action.populationStat
			};
		case FETCH_SECTION_STUDENTS_FAIL:
			return {
				...state,
				fetching: false,
				fetched: false,
				fetchError: action.error.message
			};
		case EDIT_STUDENT:
			return {
				...state,
				edit: action.studentId || null
			};
		case FETCH_CLASS_STUDENTS_START:
			return {
				...state,
				fetching: true,
				fetched: false,
				fetchError: null,
				[`subjectClassStudents_${action.subjectClass}`]:
					state[`subjectClassStudents_${action.subjectClass}`] || []
			};
		case FETCH_CLASS_STUDENTS_SUCCESS:
			return {
				...state,
				fetching: false,
				fetched: true,
				[`subjectClassStudents_${action.subjectClass}`]: action.students
			};
		case FETCH_CLASS_STUDENTS_FAIL:
			return {
				...state,
				fetching: false,
				fetched: false,
				fetchError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};
export default studentReducer;
