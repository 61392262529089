import { ADD_ATTENDANCE_START, ADD_ATTENDANCE_SUCCESS, ADD_ATTENDANCE_FAIL } from './actions';

const initState = {
	submitting: false,
	submitted: false,
	submitData: null,
	submitError: null
};

const attendanceReducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_ATTENDANCE_START:
			return {
				...state,
				submitting: true,
				submitted: false,
				submitError: null,
				submitData: action.attendanceData
			};
		case ADD_ATTENDANCE_SUCCESS:
			return {
				...state,
				submitting: false,
				submitted: true
			};
		case ADD_ATTENDANCE_FAIL:
			return {
				...state,
				submitting: false,
				submitted: false,
				submitError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default attendanceReducer;
