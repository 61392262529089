import {
  FETCH_SCHOOL_TEACHERS_START,
  FETCH_SCHOOL_TEACHERS_SUCCESS,
  FETCH_SCHOOL_TEACHERS_FAIL,
  FETCH_SCHOOL_TEACHERS,
} from "./actions";

const initState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  list: [],
  listSize: 16,
  listHasMore: true,
};

const teacherReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_SCHOOL_TEACHERS_START:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetchError: null,
        [`teachers_${action.school}`]: state[`teachers_${action.school}`] || [],
      };
    case FETCH_SCHOOL_TEACHERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        [`teachers_${action.school}`]: action.teachers,
      };
    case FETCH_SCHOOL_TEACHERS_FAIL:
      return {
        ...state,
        fetching: false,
        fetched: false,
        fetchError: action.error.message,
      };
    case FETCH_SCHOOL_TEACHERS:
      return {
        ...state,
        list: action.teachers,
        listHasMore: action.count >= state.listSize,
        listSize: state.listSize + 8,
      };
    default:
      return {
        ...state,
      };
  }
};

export default teacherReducer;
