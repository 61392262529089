import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore, { rrfConfig } from './store/configureStore';
import firebase from './constants/firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const initialState = window && window.__INITIAL_STATE__; // set initial state here
const store = configureStore(initialState);

function AuthIsLoaded({ children }) {
	const auth = useSelector((state) => state.firebase.auth);
	if (!auth.isLoaded) return <div className="loading" />;
	return children;
}

// let render = () => {
ReactDOM.render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider
			firebase={firebase}
			config={rrfConfig}
			dispatch={store.dispatch}
			createFirestoreInstance={createFirestoreInstance}
		>
			<AuthIsLoaded>
				<Suspense fallback={<div className="loading" />}>
					<App />
				</Suspense>
			</AuthIsLoaded>
		</ReactReduxFirebaseProvider>
	</Provider>,
	document.getElementById('root')
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();