import { addActivityLog } from '../logs/actions';
import { CLASSROOMS } from '../../constants/collections';

export const CREATE_CLASSROOM_START = 'CREATE_CLASSROOM_START',
	CREATE_CLASSROOM_SUCCESS = 'CREATE_CLASSROOM_SUCCESS',
	CREATE_CLASSROOM_FAIL = 'CREATE_CLASSROOM_FAIL';

const createNewClassroomStart = (classroom) => ({
	type: CREATE_CLASSROOM_START,
	classroom
});

const createNewClassroomSuccess = () => ({
	type: CREATE_CLASSROOM_SUCCESS
});

const createNewClassroomFail = (error) => ({
	type: CREATE_CLASSROOM_FAIL,
	error: error
});

export const createNewClassroom = (classroom) => async (dispatch, getState, { getFirestore }) => {
	dispatch(createNewClassroomStart(classroom.name));
	try {
		const firestore = getFirestore();
		const newClassroomRef = firestore.collection(CLASSROOMS).doc();
		await newClassroomRef.set({
			...classroom,
			createdOn: firestore.FieldValue.serverTimestamp(),
			lastUpdated: firestore.FieldValue.serverTimestamp(),
			deleted: false
		});
		dispatch(createNewClassroomSuccess());
		const newClassroomDoc = await newClassroomRef.get();
		const newClassroom = { ...newClassroomDoc.data(), id: newClassroomDoc.id };
		dispatch(
			addActivityLog({
				doc: newClassroom,
				action: 'createNewClassroom',
				actor: classroom.createdBy
			})
		);
	} catch (error) {
		console.log(error);
		dispatch(createNewClassroomFail(error));
	}
};
