import { addActivityLog } from "../logs/actions";
import {
  SECTIONS,
  EMPLOYEES,
  ADVISORIES,
  CLASSES,
  STUDENTS,
  MASTER_GRADING_SHEETS,
  ATTENDANCE,
  SCHOOLS,
} from "../../constants/collections";
import {
  addAdvisoryToEmployee,
  removeAdvisoryToEmployee,
} from "../../helpers/employees";
import {
  addAdviserToSection,
  addClassroomToSection,
} from "../../helpers/sections";
import { addSectionToSchool } from "../../helpers/schools";
import isEmpty from "lodash/isEmpty";
import {
  addSectionToClassroom,
  removeSectionToClassroom,
} from "../../helpers/classrooms";
import map from "lodash/map";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";
import { asyncForEach } from "../../helpers/Utils";
import { pick } from "lodash-es";

export const CREATE_SECTION_START = "CREATE_SECTION_START",
  CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS",
  CREATE_SECTION_FAIL = "CREATE_SECTION_FAIL",
  ADD_ATTENDANCE_START = "ADD_ATTENDANCE_START",
  ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS",
  ADD_ATTENDANCE_FAIL = "ADD_ATTENDANCE_FAIL",
  CREATE_MASTER_GRADING_SHEET_START = "CREATE_MASTER_GRADING_SHEET_START",
  CREATE_MASTER_GRADING_SHEET_FAIL = "CREATE_MASTER_GRADING_SHEET_FAIL",
  CREATE_MASTER_GRADING_SHEET_SUCCESS = "CREATE_MASTER_GRADING_SHEET_SUCCESS",
  ADD_MONTH_SCHOOL_DAYS = "ADD_MONTH_SCHOOL_DAYS",
  ADD_EXISTING_SECTIONS = "ADD_EXISTING_SECTIONS";

const createNewSectionStart = (newSection) => ({
  type: CREATE_SECTION_START,
  newSection,
});

const createNewSectionSuccess = () => ({
  type: CREATE_SECTION_SUCCESS,
});

const createNewSectionFail = (error) => ({
  type: CREATE_SECTION_FAIL,
  error: error,
});

export const createNewSection =
  ({
    section,
    school,
    sy,
    grade,
    adviser,
    classroom,
    oldAdviser,
    oldClassroom,
    sectionId,
    edit,
  }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(createNewSectionStart(section.name));
    try {
      const db = getFirestore();
      // First, check if section name already existed in school
      const sectionsQuery = db
        .collection(SECTIONS)
        .where("name", "==", section.name)
        .where("school", "==", school);
      const sectionsQuerySnap = await sectionsQuery.get();
      if (!edit && !sectionsQuerySnap.empty) {
        throw new Error("query-error-name");
      } else if (edit && !sectionsQuerySnap.empty) {
        const docIds = map(sectionsQuerySnap.docs, "id");
        if (!includes(docIds, sectionId)) {
          throw new Error("query-error-name");
        }
      }

      if (adviser) {
        // Second, check if adviser has existing advisory
        const teacherAdvisoryRef = db
          .collection(EMPLOYEES)
          .doc(adviser.value)
          .collection(ADVISORIES)
          .doc(sy);
        const teacherAdvisorySnap = await teacherAdvisoryRef.get();

        if (
          teacherAdvisorySnap.exists &&
          !isEmpty(teacherAdvisorySnap.data()[ADVISORIES])
        ) {
          if (!edit) {
            throw new Error("query-error-adviser");
          } else {
            const advisories = teacherAdvisorySnap.data()[ADVISORIES];
            if (!includes(advisories, sectionId)) {
              throw new Error("query-error-adviser");
            }
          }
        }
      }

      const newSectionRef = !edit
        ? db.collection(SECTIONS).doc()
        : db.collection(SECTIONS).doc(sectionId);
      if (!edit) {
        await newSectionRef.set({
          ...section,
          createdOn: db.FieldValue.serverTimestamp(),
          lastUpdated: db.FieldValue.serverTimestamp(),
          deleted: false,
        });
      } else {
        await newSectionRef.set(
          {
            ...section,
            lastUpdated: db.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      }
      dispatch(createNewSectionSuccess());

      if (adviser) {
        // Add adviser to subcollection "advisers" of section doc
        await addAdviserToSection(newSectionRef.id, adviser, sy);

        // Add section to subcollection "advisory" in Employee doc
        await addAdvisoryToEmployee(adviser.value, sy, newSectionRef.id);
      }

      // Add section to subcollection "sections" in school doc
      await addSectionToSchool(school, sy, newSectionRef.id, grade.value);

      if (classroom) {
        // Add classroom to subcollection "classrooms" of section doc
        await addClassroomToSection(newSectionRef.id, classroom, sy);

        // Add section to subcollection "sections" of classroom doc
        await addSectionToClassroom(classroom.value, newSectionRef.id, sy);
      }

      // If editing
      if (edit) {
        // If old and new advisers are not equal...
        if (!isEqual(adviser, oldAdviser)) {
          if (isNil(adviser)) {
            // if new adviser is null, remove adviser from section
            await addAdviserToSection(newSectionRef.id, null, sy);
          } else if (
            !isNil(adviser) &&
            !isNil(oldAdviser) &&
            !isEqual(adviser.value, oldAdviser.value)
          ) {
            // remove section from old adviser
            await removeAdvisoryToEmployee(
              oldAdviser.value,
              sy,
              newSectionRef.id
            );
          }
        }
        // If old and new classrooms are not equal...
        if (!isEqual(classroom, oldClassroom)) {
          if (isNil(classroom)) {
            // if new classroom is null, remove classroom from section
            await addClassroomToSection(newSectionRef.id, null, sy);
          } else if (
            !isNil(classroom) &&
            !isNil(oldClassroom) &&
            !isEqual(classroom.value, oldClassroom.value)
          ) {
            // remove section from old classroom
            await removeSectionToClassroom(
              oldClassroom.value,
              newSectionRef.id,
              sy
            );
          }
        }
      }

      const newSectionDoc = await newSectionRef.get();
      const newSection = { ...newSectionDoc.data(), id: newSectionDoc.id };
      dispatch(
        addActivityLog({
          doc: newSection,
          action: !edit ? "createNewSection" : "updateSection",
          actor: !edit ? section.createdBy : section.updatedBy,
        })
      );
    } catch (error) {
      let errorBody = error;
      if (error.message === "query-error-name") {
        errorBody = {
          message: "Section name already taken.",
          field: "name",
        };
      } else if (error.message === "query-error-adviser") {
        errorBody = {
          message: "This teacher already has advisory.",
          field: "adviser",
        };
      }
      dispatch(createNewSectionFail(errorBody));
    }
  };

const createMasterGradingSheetStart = (section, sy) => ({
  type: CREATE_MASTER_GRADING_SHEET_START,
  section,
  sy,
});

const createMasterGradingSheetSuccess = (section, sy, gradingSheetData) => ({
  type: CREATE_MASTER_GRADING_SHEET_SUCCESS,
  section,
  sy,
  gradingSheetData,
});

const createMasterGradingSheetFail = (section, sy, error) => ({
  type: CREATE_MASTER_GRADING_SHEET_FAIL,
  section,
  sy,
  error,
});

export const createMasterGradingSheet =
  ({ subjectClasses, students, section, sy }, forceCreate = false) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(createMasterGradingSheetStart(section, sy));
    try {
      let sectionClasses = [],
        sectionStudents = [];
      const db = getFirestore();
      const classRef = db.collection(CLASSES);
      const studentRef = db.collection(STUDENTS);
      const masterGradingSheetRef = db.collection(MASTER_GRADING_SHEETS);

      async function createMGS() {
        // Get all Subject Classes and return only id, subject.label, and semester
        await asyncForEach(subjectClasses, async (subject) => {
          const subjectSnap = await classRef.doc(subject).get();
          const subjectData = subjectSnap.data();
          sectionClasses.push({
            id: subjectSnap.id,
            subject: subjectData.subject && subjectData.subject.label,
            subjectId: subjectData.subject && subjectData.subject.value,
            semester: subjectData.semester,
          });
        });

        // Iterate through students
        await asyncForEach(students, async (studentId) => {
          // Get student data - names, gender, lrn
          const studentSnap = await studentRef.doc(studentId).get();
          const studentData = studentSnap.data();
          const studentBasicInfo = pick(studentData, [
            "firstName",
            "lastName",
            "middleName",
            "lrn",
            "gender",
          ]);

          // Query student data in subject -> student subcollection
          let studentGrades = {};
          const studentClassesRef = studentRef
            .doc(studentId)
            .collection(CLASSES)
            .doc(sy);

          const studentClassSnap = await studentClassesRef.get();
          if (studentClassSnap.exists) {
            const studentClassData = studentClassSnap.data();
            studentGrades = studentClassData.grades || null;
          }

          sectionStudents.push({
            id: studentSnap.id,
            ...studentBasicInfo,
            grades: studentGrades,
          });
        });

        // Create a Master Grading Sheet doc for this
        const sectionMasterListRef = masterGradingSheetRef.doc(
          `${section}_${sy}`
        );
        const newMGSData = {
          section,
          sy,
          classes: sectionClasses,
          students: sectionStudents,
          lastUpdated: db.FieldValue.serverTimestamp(),
        };
        await sectionMasterListRef.set(newMGSData);
        // Dispatch
        dispatch(
          createMasterGradingSheetSuccess(section, sy, {
            classes: sectionClasses,
            students: sectionStudents,
          })
        );
      }

      if (forceCreate) {
        await createMGS();
      } else {
        // Try to get the master grading sheet doc first
        const mgsSnap = await masterGradingSheetRef
          .doc(`${section}_${sy}`)
          .get();

        if (mgsSnap.exists) {
          const masterGradingSheet = {
            id: mgsSnap.id,
            ...mgsSnap.data(),
          };
          // Dispatch
          dispatch(
            createMasterGradingSheetSuccess(section, sy, {
              classes: masterGradingSheet.classes,
              students: masterGradingSheet.students,
            })
          );
        } else {
          await createMGS();
        }
      }
    } catch (error) {
      console.log(error.message);
      dispatch(createMasterGradingSheetFail(section, sy, error.message));
    }
  };

export const addSchoolMonth =
  ({ section, sy, month, num, semester, order, excludeFromSY }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const db = getFirestore();
      const sectionRef = db
        .collection(SECTIONS)
        .doc(section)
        .collection(ATTENDANCE)
        .doc(`${sy}_settings`);

      await sectionRef.set(
        {
          numSchoolDays: {
            [month]: num,
          },
          semester: {
            [month]: semester,
          },
          order: {
            [month]: order,
          },
          exclude: excludeFromSY ? db.FieldValue.arrayUnion(month) : db.FieldValue.arrayRemove(month)
        },
        { merge: true }
      );

      dispatch({ type: ADD_MONTH_SCHOOL_DAYS });
    } catch (error) {
      throw error.message;
    }
  };

export const addExistingSections = ({
  currentSY,
  fromSY,
  schoolId
}) => async (dispatch, getState, {getFirestore}) => {
  try {
    const db = getFirestore();
    // Get the existing subcollection SECTIONS in school
    const schoolSectionsRef = db.collection(SCHOOLS).doc(schoolId).collection(SECTIONS)
    const fromSYSectionsSnap = await schoolSectionsRef.doc(fromSY).get()
    if (fromSYSectionsSnap.exists) {
      const fromSYSections = fromSYSectionsSnap.data()
      await schoolSectionsRef.doc(currentSY).set(fromSYSections, {merge: true})
    }
    dispatch({type: ADD_EXISTING_SECTIONS})
  } catch (error) {
    throw error.message
  }
}
