import {
	CREATE_CLASS_START,
	CREATE_CLASS_SUCCESS,
	CREATE_CLASS_FAIL,
	FETCH_SECTION_CLASSES_START,
	FETCH_SECTION_CLASSES_SUCCESS,
	FETCH_SECTION_CLASSES_FAIL,
	FETCH_STUDENT_CLASSES_START,
	FETCH_STUDENT_CLASSES_SUCCESS,
	FETCH_STUDENT_CLASSES_FAIL,
	FETCH_TEACHER_CLASSES_START,
	FETCH_TEACHER_CLASSES_SUCCESS,
	FETCH_TEACHER_CLASSES_FAIL,
	EDIT_CLASS,
	INPUT_CLASS_GRADES,
	INPUT_STUDENT_GRADE_START,
	INPUT_STUDENT_GRADE_SUCCESS,
	INPUT_STUDENT_GRADE_FAIL,
	CREATE_VIDEO_MEETING_START,
	CREATE_VIDEO_MEETING_SUCCESS,
	CREATE_VIDEO_MEETING_FAIL
} from './actions';

const initState = {
	creating: false,
	created: false,
	createError: null,
	newSectionSubject: '',
	fetching: false,
	fetched: false,
	fetchError: null,
	edit: null,
	inputGrades: null,
	inputingGrades: false,
	inputedGrades: false,
	inputGradesError: null,
	addingVideoMeeting: false,
	addedVideoMeeting: false,
	addVideoMeetingError: null,
	addVideoMeetingName: ''
};

const classReducer = (state = initState, action) => {
	switch (action.type) {
		case CREATE_CLASS_START:
			return {
				...state,
				creating: true,
				created: false,
				createError: null,
				newSectionSubject: action.newClass
			};
		case CREATE_CLASS_SUCCESS:
			return {
				...state,
				creating: false,
				created: true,
				createError: null
			};
		case CREATE_CLASS_FAIL:
			return {
				...state,
				creating: false,
				created: false,
				createError: action.error
			};
		case FETCH_SECTION_CLASSES_START:
			return {
				...state,
				fetching: true,
				fetched: false,
				fetchError: null,
				[`sectionClasses_${action.section}`]: state[`sectionClasses_${action.section}`] || []
			};
		case FETCH_SECTION_CLASSES_SUCCESS:
			return {
				...state,
				fetching: false,
				fetched: true,
				[`sectionClasses_${action.section}`]: action.classes
			};
		case FETCH_SECTION_CLASSES_FAIL:
			return {
				...state,
				fetching: false,
				fetched: false,
				fetchError: action.error.message
			};
		case FETCH_STUDENT_CLASSES_START:
			return {
				...state,
				fetching: true,
				fetched: false,
				fetchError: null,
				[`studentClasses_${action.sy}_${action.student}`]: state[`studentClasses_${action.sy}_${action.student}`] || []
			};
		case FETCH_STUDENT_CLASSES_SUCCESS:
			return {
				...state,
				fetching: false,
				fetched: true,
				[`studentClasses_${action.sy}_${action.student}`]: action.classes
			};
		case FETCH_STUDENT_CLASSES_FAIL:
			return {
				...state,
				fetching: false,
				fetched: false,
				fetchError: action.error.message
			};
		case FETCH_TEACHER_CLASSES_START:
			return {
				...state,
				fetching: true,
				fetched: false,
				fetchError: null,
				[`teacherClasses_${action.teacher}`]: state[`teacherClasses_${action.teacher}`] || []
			};
		case FETCH_TEACHER_CLASSES_SUCCESS:
			return {
				...state,
				fetching: false,
				fetched: true,
				[`teacherClasses_${action.teacher}`]: action.classes
			};
		case FETCH_TEACHER_CLASSES_FAIL:
			return {
				...state,
				fetching: false,
				fetched: false,
				fetchError: action.error.message
			};
		case EDIT_CLASS:
			return {
				...state,
				edit: action.classId || null
			};
		case INPUT_CLASS_GRADES:
			return {
				...state,
				inputGrades: action.subjectClass || null
			};
		case INPUT_STUDENT_GRADE_START:
			return {
				...state,
				inputingGrades: true,
				inputedGrades: false,
				inputGradesError: null
			};
		case INPUT_STUDENT_GRADE_SUCCESS:
			return {
				...state,
				inputingGrades: false,
				inputedGrades: true
			};
		case INPUT_STUDENT_GRADE_FAIL:
			return {
				...state,
				inputingGrades: false,
				inputedGrades: false,
				inputGradesError: action.error.message
			};
		case CREATE_VIDEO_MEETING_START:
			return {
				...state,
				addingVideoMeeting: true,
				addedVideoMeeting: false,
				addVideoMeetingName: action.roomName
			};
		case CREATE_VIDEO_MEETING_SUCCESS:
			return {
				...state,
				addingVideoMeeting: false,
				addedVideoMeeting: true
			};
		case CREATE_VIDEO_MEETING_FAIL:
			return {
				...state,
				addingVideoMeeting: false,
				addedVideoMeeting: false,
				addVideoMeetingError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default classReducer;
