import {
	ADD_CONTENT_START,
	ADD_CONTENT_SUCCESS,
	ADD_CONTENT_FAIL,
	REMOVE_CONTENT_START,
	REMOVE_CONTENT_SUCCESS,
	REMOVE_CONTENT_FAIL
} from './actions';

const initState = {
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

const sbmReducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_CONTENT_START:
			return {
				...state,
				adding: true,
				added: false
			};
		case ADD_CONTENT_SUCCESS:
			return {
				...state,
				adding: false,
				added: true
			};
		case ADD_CONTENT_FAIL:
			return {
				...state,
				adding: false,
				added: false,
				addError: action.error.message
			};
		case REMOVE_CONTENT_START:
			return {
				...state,
				deleting: true,
				deleted: false
			};
		case REMOVE_CONTENT_SUCCESS:
			return {
				...state,
				deleting: false,
				deleted: true
			};
		case REMOVE_CONTENT_FAIL:
			return {
				...state,
				deleting: false,
				deleted: false,
				deleteError: action.error.message
			};
		default:
			return {
				...state
			};
	}
};

export default sbmReducer;
