import difference from 'lodash/difference';

export const STUDENT = 'student',
	TEACHER = 'teacher',
	STAFF = 'staff',
	SCHOOL_ADMIN = 'schoolAdmin',
	DIVISION_ADMIN = 'divisionAdmin',
	SUPERADMIN = 'superadmin';

export const schoolRoles = [STUDENT, TEACHER, STAFF, SCHOOL_ADMIN];
export const allRoles = [STUDENT, TEACHER, STAFF, SCHOOL_ADMIN, DIVISION_ADMIN, SUPERADMIN];
export const excludeRoles = (roles) => difference(allRoles, roles);
export const roles = [
	{ value: STUDENT, label: 'Student', sort: 4 },
	{ value: TEACHER, label: 'Teacher', sort: 4 },
	{ value: STAFF, label: 'Staff', sort: 4 },
	{ value: SCHOOL_ADMIN, label: 'School Admin', sort: 3 },
	{ value: DIVISION_ADMIN, label: 'Division Admin', sort: 2 },
	{ value: SUPERADMIN, label: 'Superadmin', sort: 1 }
];
export const accountStatus = [
	{ value: 'pending', label: 'Pending', color: 'warning' },
	{ value: 'enabled', label: 'Enabled', color: 'success' },
	{ value: 'disabled', label: 'Disabled', color: 'danger' },
	{ value: 'none', label: 'None', color: 'dark' }
];
