import firebase from '../constants/firebase';
import { SECTIONS, CLASSROOMS, CLASSES } from '../constants/collections';

export const addSectionToClassroom = async (classroomId, sectionId, sy) => {
	const db = firebase.firestore();
	const classroomRef = db.collection(CLASSROOMS).doc(classroomId).collection(SECTIONS).doc(sy);
	return await classroomRef.set(
		{
			[SECTIONS]: firebase.firestore.FieldValue.arrayUnion(sectionId)
		},
		{ merge: true }
	);
};

export const removeSectionToClassroom = async (classroomId, sectionId, sy) => {
	const db = firebase.firestore();
	const classroomRef = db.collection(CLASSROOMS).doc(classroomId).collection(SECTIONS).doc(sy);
	return await classroomRef.set(
		{
			[SECTIONS]: firebase.firestore.FieldValue.arrayRemove(sectionId)
		},
		{ merge: true }
	);
};

export const addClassToClassroom = async (classroomId, classId, sy) => {
	const db = firebase.firestore();
	const classroomRef = db.collection(CLASSROOMS).doc(classroomId).collection(CLASSES).doc(sy);
	return await classroomRef.set(
		{
			[CLASSES]: firebase.firestore.FieldValue.arrayUnion(classId)
		},
		{ merge: true }
	);
};

export const removeClassToClassroom = async (classroomId, classId, sy) => {
	const db = firebase.firestore();
	const classroomRef = db.collection(CLASSROOMS).doc(classroomId).collection(CLASSES).doc(sy);
	return await classroomRef.set(
		{
			[CLASSES]: firebase.firestore.FieldValue.arrayRemove(classId)
		},
		{ merge: true }
	);
};
