import firebase from "../constants/firebase";
import {
  SECTIONS,
  ADVISERS,
  STUDENTS,
  CLASSROOMS,
  CLASSES,
  ATTENDANCE,
  IDX_SECTIONS,
} from "../constants/collections";

export const addAdviserToSection = async (sectionId, adviser, sy) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(sectionId)
    .collection(ADVISERS)
    .doc(sy);
  return await sectionRef.set({ adviser }, { merge: true });
};

export const addStudentToSection = async (
  sectionId,
  studentId,
  sy,
  unenroll = false
) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(sectionId)
    .collection(STUDENTS)
    .doc(sy);
  return await sectionRef.set(
    {
      [STUDENTS]: !unenroll
        ? firebase.firestore.FieldValue.arrayUnion(studentId)
        : firebase.firestore.FieldValue.arrayRemove(studentId),
    },
    { merge: true }
  );
};

export const addClassroomToSection = async (sectionId, classroom, sy) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(sectionId)
    .collection(CLASSROOMS)
    .doc(sy);
  return await sectionRef.set({ classroom }, { merge: true });
};

export const getAllStudentsInSection = async (sectionId, sy) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(sectionId)
    .collection(STUDENTS)
    .doc(sy);
  const sectionSnap = await sectionRef.get();
  return sectionSnap.exists ? sectionSnap.data().students : [];
};

export const addClassToSection = async (sectionId, sy, classId) => {
  const db = firebase.firestore();
  const sectionRef = db
    .collection(SECTIONS)
    .doc(sectionId)
    .collection(CLASSES)
    .doc(sy);
  return await sectionRef.set(
    {
      [CLASSES]: firebase.firestore.FieldValue.arrayUnion(classId),
    },
    { merge: true }
  );
};

export const addAttendanceToStudent = async (
  student,
  attendanceId,
  attendanceData
) => {
  const db = firebase.firestore();
  const studentAttendanceRef = db
    .collection(STUDENTS)
    .doc(student)
    .collection(ATTENDANCE)
    .doc(attendanceId);
  return await studentAttendanceRef.set({ ...attendanceData }, { merge: true });
};

export const searchSection = async (keyword, schoolId, limit = 0) => {
  try {
    console.log(keyword, schoolId);
    const db = firebase.firestore();
    let sectionsRef = db
      .collection(IDX_SECTIONS)
      .where("keywords", "array-contains", keyword)
      .where("school", "==", schoolId);
    if (limit) {
      sectionsRef.limit(limit);
    }
    return await sectionsRef.get();
  } catch (error) {
    throw error;
  }
};
