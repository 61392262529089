import { addActivityLog } from '../logs/actions';
import { GRADE_LEVELS } from '../../constants/collections';

export const CREATE_GRADE_LEVEL_START = 'CREATE_GRADE_LEVEL_START',
	CREATE_GRADE_LEVEL_SUCCESS = 'CREATE_GRADE_LEVEL_SUCCESS',
	CREATE_GRADE_LEVEL_FAIL = 'CREATE_GRADE_LEVEL_FAIL';

const createNewGradeLevelStart = (newGradeLevel) => ({
	type: CREATE_GRADE_LEVEL_START,
	newGradeLevel
});

const createNewGradeLevelSuccess = () => ({
	type: CREATE_GRADE_LEVEL_SUCCESS
});

const createNewGradeLevelFail = (error) => ({
	type: CREATE_GRADE_LEVEL_FAIL,
	error: error
});

export const createNewGradeLevel = (gradeLevel) => async (dispatch, getState, { getFirestore }) => {
	dispatch(createNewGradeLevelStart(gradeLevel.name));
	try {
		const firestore = getFirestore();
		const newGradeLevelRef = firestore.collection(GRADE_LEVELS).doc();
		await newGradeLevelRef.set({
			...gradeLevel,
			createdOn: firestore.FieldValue.serverTimestamp(),
			lastUpdated: firestore.FieldValue.serverTimestamp(),
			deleted: false
		});
		dispatch(createNewGradeLevelSuccess());
		const newGradeLevelDoc = await newGradeLevelRef.get();
		const newGradeLevel = { ...newGradeLevelDoc.data(), id: newGradeLevelDoc.id };
		dispatch(
			addActivityLog({
				doc: newGradeLevel,
				action: 'createNewGradeLevel',
				actor: gradeLevel.createdBy
			})
		);
	} catch (error) {
		console.log(error);
		dispatch(createNewGradeLevelFail(error));
	}
};
