import { SBM_DIRECTORIES, SBM_CONTENTS } from '../../constants/collections';
import { addNodeUnderParent, removeNodeAtPath, getNodeAtPath, walk } from 'react-sortable-tree';
import { makeId } from '../../helpers/Utils';

export const ADD_CONTENT_START = 'ADD_CONTENT_START',
	ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS',
	ADD_CONTENT_FAIL = 'ADD_CONTENT_FAIL',
	REMOVE_CONTENT_START = 'REMOVE_CONTENT_START',
	REMOVE_CONTENT_SUCCESS = 'REMOVE_CONTENT_SUCCESS',
	REMOVE_CONTENT_FAIL = 'REMOVE_CONTENT_FAIL';

const addContentStart = () => ({
	type: ADD_CONTENT_START
});

const addContentSuccess = () => ({
	type: ADD_CONTENT_SUCCESS
});

const addContentFail = (error) => ({
	type: ADD_CONTENT_FAIL,
	error
});

const getNodeKey = ({ node }) => node.contentId;

const getUpdatedDirectory = (treeData, path, newContent) => {
	return addNodeUnderParent({
		treeData,
		parentKey: path[path.length - 1],
		expandParent: true,
		getNodeKey,
		newNode: newContent,
		addAsFirstChild: false
	}).treeData;
};

const getUpdatedDirectoryAfterDeleteContent = (directory, path) => {
	const removedNode = getNodeAtPath({
		treeData: directory,
		path,
		getNodeKey
	});
	const updatedDirectory = removeNodeAtPath({
		treeData: directory,
		path,
		getNodeKey
	});

	return [removedNode, updatedDirectory];
};

export const addSBMContent = (sbm) => async (dispatch, getState, { getFirestore }) => {
	dispatch(addContentStart());
	try {
		const db = getFirestore();
		let sbmPageRef;
		if (sbm.type === 'page') {
			sbmPageRef = db.collection(SBM_CONTENTS).doc();
			await sbmPageRef.set({
				title: 'Untitled Page',
				school: sbm.school,
				createdOn: db.FieldValue.serverTimestamp(),
				createdBy: sbm.creator,
				lastUpdated: db.FieldValue.serverTimestamp(),
				deleted: false
			});
		}
		const directoryRef = db.collection(SBM_DIRECTORIES).doc(sbm.id);
		const newContent = {
			title: `Untitled ${sbm.type === 'page' ? 'Page' : 'Folder'}`,
			contentId: sbm.type === 'page' ? sbmPageRef.id : `folder-${makeId(8)}`,
			type: sbm.type
		};
		const updatedDirectory = sbm.parent
			? [...sbm.directory, newContent]
			: getUpdatedDirectory(sbm.directory, sbm.parentPath, newContent);

		await directoryRef.set(
			{
				directory: updatedDirectory,
				lastUpdated: db.FieldValue.serverTimestamp()
			},
			{ merge: true }
		);
		dispatch(addContentSuccess());
	} catch (error) {
		dispatch(addContentFail(error));
	}
};

export const editSBMContent = (sbmDir) => async (dispatch, getState, { getFirestore }) => {
	dispatch(addContentStart());
	try {
		const db = getFirestore();
		const directoryRef = db.collection(SBM_DIRECTORIES).doc(sbmDir.id);
		await directoryRef.set(
			{
				directory: sbmDir.updatedDirectory,
				lastUpdated: db.FieldValue.serverTimestamp()
			},
			{ merge: true }
		);
		dispatch(addContentSuccess());
	} catch (error) {
		dispatch(addContentFail(error));
	}
};

const removeContentStart = () => ({
	type: REMOVE_CONTENT_START
});

const removeContentSuccess = () => ({
	type: REMOVE_CONTENT_SUCCESS
});

const removeContentFail = (error) => ({
	type: REMOVE_CONTENT_FAIL,
	error
});

export const deleteSBMContent = (sbmDir) => async (dispatch, getState, { getFirestore }) => {
	dispatch(removeContentStart());
	try {
		const db = getFirestore();
		// Get the removed node and children and the updated directory
		const [removedNode, updatedDirectory] = getUpdatedDirectoryAfterDeleteContent(sbmDir.directory, sbmDir.path);
		// Update the directory
		const directoryRef = db.collection(SBM_DIRECTORIES).doc(sbmDir.id);
		await directoryRef.set({
			directory: updatedDirectory,
			lastUpdated: db.FieldValue.serverTimestamp()
		});
		// Mark removed content/s as deleted
		walk({
			treeData: [removedNode.node],
			getNodeKey,
			callback: async ({ node }) => {
				const sbmContents = db.collection(SBM_CONTENTS);
				if (node && node.type === 'page') {
					const sbmContentRef = sbmContents.doc(node.contentId);
					return await sbmContentRef.set(
						{
							deleted: true,
							lastUpdated: db.FieldValue.serverTimestamp(),
							deletedBy: sbmDir.actor
						},
						{ merge: true }
					);
				}
				return true;
			}
		});
		dispatch(removeContentSuccess());
	} catch (error) {
		dispatch(removeContentFail(error));
	}
};
