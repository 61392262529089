import countBy from "lodash/countBy";
import moment from "moment";
import {
  SECTIONS,
  ATTENDANCE,
  STUDENTS,
  SCHOOLS,
  ABSENCES,
} from "../../constants/collections";
import { addActivityLog } from "../logs/actions";

export const ADD_ATTENDANCE_START = "ADD_ATTENDANCE_START",
  ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS",
  ADD_ATTENDANCE_FAIL = "ADD_ATTENDANCE_FAIL";

const addSectionAttendanceStart = (attendanceData) => ({
  type: ADD_ATTENDANCE_START,
  attendanceData,
});

const addSectionAttendanceSuccess = () => ({
  type: ADD_ATTENDANCE_SUCCESS,
});

const addSectionAttendanceFail = (error) => ({
  type: ADD_ATTENDANCE_FAIL,
  error,
});

export const addSectionAttendance =
  ({
    attendance,
    attendanceId,
    currentDate,
    sy,
    section,
    period,
    sectionStudents,
    createdBy,
    edit,
    updatedBy,
    schoolId,
  }) =>
  async (dispatch, getState, { getFirestore }) => {
    dispatch(
      addSectionAttendanceStart({
        currentDate,
        period,
      })
    );
    try {
      const currentDateAll = moment(new Date(currentDate));
      const currentDateData = {
        year: currentDateAll.format("YYYY"),
        month: currentDateAll.format("MMMM"),
        dateTimestamp: new Date(currentDate),
      };
      const db = getFirestore();
      const attendanceBatch = db.batch();
      const studentRef = db.collection(STUDENTS);

      sectionStudents.forEach((student) => {
        const studentIsPresent = attendance[student];
        const studentAttendanceRef = studentRef
          .doc(student)
          .collection(ATTENDANCE)
          .doc(attendanceId);
        attendanceBatch.set(
          studentAttendanceRef,
          {
            period,
            present: studentIsPresent,
            sy,
            date: currentDate,
            ...currentDateData,
          },
          { merge: true }
        );
      });

      const statistics = countBy(attendance, Boolean);
      const sectionAttendanceRef = db
        .collection(SECTIONS)
        .doc(section)
        .collection(ATTENDANCE)
        .doc(attendanceId);

      const attendanceData = {
        period,
        present: statistics.true || 0,
        absent: statistics.false || 0,
        sy,
        section,
        date: currentDate,
        deleted: false,
        ...currentDateData,
      };

      console.log("attendanceData", attendanceData);

      if (!edit) {
        attendanceBatch.set(
          sectionAttendanceRef,
          {
            ...attendanceData,
            createdBy,
            createdOn: db.FieldValue.serverTimestamp(),
            lastUpdated: db.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      } else {
        attendanceBatch.set(
          sectionAttendanceRef,
          { ...attendanceData, updatedBy },
          { merge: true }
        );
      }

      // Reference to the document with the current SY in the subcollection "absences"
      const absenceDocRef = db
        .collection(SCHOOLS)
        .doc(schoolId)
        .collection(ABSENCES)
        .doc(sy);

      attendanceBatch.set(
        absenceDocRef,
        {
          [currentDateData.month]: db.FieldValue.increment(
            statistics.false / 2 || 0
          ),
        },
        { merge: true }
      );

      await attendanceBatch.commit();
      dispatch(addSectionAttendanceSuccess());

      // Log
      const newAttendanceSnap = await sectionAttendanceRef.get();
      const newAttendance = {
        ...newAttendanceSnap.data(),
        id: newAttendanceSnap.id,
      };
      dispatch(
        addActivityLog({
          doc: newAttendance,
          action: !edit ? "addNewAttendance" : "updateNewAttendance",
          actor: !edit ? createdBy : updatedBy,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(addSectionAttendanceFail(error));
    }
  };
