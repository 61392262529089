import { getAppUserToken } from '../../helpers/auth';

export const INPUT_SEARCH_KEYWORD = 'INPUT_SEARCH_KEYWORD',
	SEARCH_REGISTRY_START = 'SEARCH_REGISTRY_START',
	SEARCH_REGISTRY_SUCCESS = 'SEARCH_REGISTRY_SUCCESS',
	SEARCH_REGISTRY_FAIL = 'SEARCH_REGISTRY_FAIL',
	INPUT_SEARCH_QUERY = 'INPUT_SEARCH_QUERY';

export const inputSearchKeyword = ({ keyword, view, collection }) => {
	return (dispatch) => {
		dispatch({
			type: INPUT_SEARCH_KEYWORD,
			keyword,
			view,
			collection
		});
	};
};

export const inputSelectQuery = ({ field, value, view, collection }) => {
	return (dispatch) => {
		dispatch({
			type: INPUT_SEARCH_QUERY,
			field,
			value,
			view,
			collection
		});
	};
};

const searchRegistryStart = ({ keyword, view, collection }) => {
	return {
		type: SEARCH_REGISTRY_START,
		keyword,
		view,
		collection
	};
};

const searchRegistrySuccess = ({ keyword, view, collection, result }) => {
	return {
		type: SEARCH_REGISTRY_SUCCESS,
		keyword,
		view,
		collection,
		result
	};
};

const searchRegistryFail = ({ keyword, view, collection, error }) => {
	return {
		type: SEARCH_REGISTRY_FAIL,
		keyword,
		view,
		collection,
		error
	};
};

export const searchStudent = ({ keyword, view, collection, school, sy }) => {
	return async (dispatch) => {
		dispatch(searchRegistryStart({ keyword, view, collection }));
		const api = process.env.REACT_APP_FUNCTIONS_API;
		const searchEndpoint = `/search/registry/${collection}?&search=${keyword}&school=${school}&sy=${sy}`;
		try {
			let response = await fetch(api + searchEndpoint, {
				headers: {
					Authorization: await getAppUserToken()
				}
			});
			if (response.status === 403) {
				throw await response.json();
			}
			let data = await response.json();
			dispatch(searchRegistrySuccess({ keyword, view, collection, result: data }));
		} catch (error) {
			console.log(error);
			dispatch(searchRegistryFail({ keyword, view, collection, error }));
		}
	};
};

export const searchEmployee = ({ keyword, view, collection, school, sy, teaching }) => {
	return async (dispatch) => {
		dispatch(searchRegistryStart({ keyword, view, collection }));
		const api = process.env.REACT_APP_FUNCTIONS_API;
		const searchEndpoint = `/search/registry/${collection}?&search=${keyword}&school=${school}&sy=${sy}&teaching=${teaching}`;
		try {
			let response = await fetch(api + searchEndpoint, {
				headers: {
					Authorization: await getAppUserToken()
				}
			});
			let data = await response.json();
			dispatch(searchRegistrySuccess({ keyword, view, collection, result: data }));
		} catch (error) {
			console.log(error);
			dispatch(searchRegistryFail({ keyword, view, collection, error }));
		}
	};
};

export const searchUsers = ({ keyword, role, school, status, view, collection }) => {
	return async (dispatch) => {
		dispatch(searchRegistryStart({ keyword, view, collection }));
		const api = process.env.REACT_APP_FUNCTIONS_API;
		const searchEndpoint = `/search/registry/${collection}?&search=${keyword}&school=${school}&role=${role}&status=${status}`;
		try {
			let response = await fetch(api + searchEndpoint, {
				headers: {
					Authorization: await getAppUserToken()
				}
			});
			let data = await response.json();
			dispatch(searchRegistrySuccess({ keyword, view, collection, result: data }));
		} catch (error) {
			console.log(error);
			dispatch(searchRegistryFail({ keyword, view, collection, error }));
		}
	};
};
