export const quarter = [
  {
    value: "1Q",
    label: "1st Quarter",
    alt: "Q1",
    semester: "sem1",
    altLabel: "First Quarter",
  },
  {
    value: "2Q",
    label: "2nd Quarter",
    alt: "Q2",
    semester: "sem1",
    altLabel: "Second Quarter",
  },
  {
    value: "3Q",
    label: "3rd Quarter",
    alt: "Q3",
    semester: "sem2",
    altLabel: "Third Quarter",
  },
  {
    value: "4Q",
    label: "4th Quarter",
    alt: "Q4",
    semester: "sem2",
    altLabel: "Fourth Quarter",
  },
];

export const final = {
  value: "final",
  label: "Final Grade",
  altLabel: "Final",
};

export const semester = [
  {
    value: "sem1",
    label: "First Semester",
    label2: "1st",
    label3: "1st Semester",
  },
  {
    value: "sem2",
    label: "Second Semester",
    label2: "2nd",
    label3: "2nd Semester",
  },
];

export const remarks = [
  {
    value: "passed",
    label: "Passed",
  },
  {
    value: "failed",
    label: "Failed",
  },
];

export const PASSED = "Passed",
  FAILED = "Failed";

export const award = (grade) => {
  if (grade >= 98) {
    return "With Highest Honors";
  } else if (grade >= 95 && grade <= 97) {
    return "With High Honors";
  } else if (grade >= 90 && grade <= 94) {
    return "With Honors";
  } else if (grade < 90) {
    return "";
  } else {
    return "-";
  }
};

export const gradeScale = [
  {
    id: "outstanding",
    label: "Outstanding",
    scale: { upper: 100, lower: 90, words: "90-100" },
    remarks: "Passed",
    color: "success",
    remarksAbbr: "P"
  },
  {
    id: "very-satisfactory",
    label: "Very Satisfactory",
    scale: { upper: 89, lower: 85, words: "85-89" },
    remarks: "Passed",
    color: "success",
    remarksAbbr: "P"
  },
  {
    id: "satisfactory",
    label: "Satisfactory",
    scale: { upper: 84, lower: 80, words: "80-84" },
    remarks: "Passed",
    color: "success",
    remarksAbbr: "P"
  },
  {
    id: "fairly-satisfactory",
    label: "Fairly Satisfactory",
    scale: { upper: 79, lower: 75, words: "75-79" },
    remarks: "Passed",
    color: "success",
    remarksAbbr: "P"
  },
  {
    id: "dnme",
    label: "Did Not Meet Expectations",
    scale: { upper: 74, lower: 0, words: "Below 75" },
    remarks: "Failed",
    color: "danger",
    remarksAbbr: "F"
  },
];
