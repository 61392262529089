import React from "react";
import { defaultDirection } from "../constants/defaultValues";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import "@gouch/to-title-case";
import map from "lodash/map";
import compact from "lodash/compact";
import trim from "lodash/trim";
import moment from "moment";
import forOwn from "lodash/forOwn";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isNaN from "lodash/isNaN";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import pull from "lodash/pull";
import cloneDeep from "lodash/cloneDeep";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import PasswordValidator from "password-validator";
import isNil from "lodash/isNil";

const passwordSchema = new PasswordValidator();
passwordSchema
  .is()
  .min(8)
  .has()
  .letters()
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .not()
  .spaces();

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.validPassword) {
    isValid = passwordSchema.validate(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isPhone && value.length) {
    const phone = parsePhoneNumberFromString(value, "US");
    isValid = phone ? phone.isValid() : false;
  }

  return isValid;
};

export const characterCounter = (value, rules) => {
  let charCounterData = {
    show: false,
  };

  if (rules.maxLength) {
    charCounterData.show = value.length >= rules.maxLength - 20;
    charCounterData.charsLeft = rules.maxLength - value.length;
  }

  return charCounterData;
};

export const addFormFeedback = (value, rules, customMessage = null) => {
  let feedback = null;
  if (!rules) {
    return feedback;
  }

  if (rules.required && value.trim() === "") {
    feedback =
      (customMessage && customMessage.required) || "This field is required.";
  } else if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!pattern.test(value)) {
      feedback =
        (customMessage && customMessage.isEmail) ||
        "Please provide a valid email.";
    }
  } else if (rules.minLength && value.length <= rules.minLength) {
    feedback =
      (customMessage && customMessage.minLength) ||
      "Should not be less than " + rules.minLength + " characters.";
  } else if (rules.validPassword) {
    if (!passwordSchema.validate(value)) {
      feedback =
        (customMessage && customMessage.validPassword) ||
        "Please provide a valid password.";
    }
  } else if (rules.isPhone) {
    const phone = parsePhoneNumberFromString(value, "US");
    if ((phone && !phone.isValid()) || (value.length && !phone)) {
      feedback =
        (customMessage && customMessage.isPhone) ||
        "Phone number is invalid. Please use this format <+country code><phone number>.";
    }
  } else if (rules.maxLength && value.length >= rules.maxLength) {
    feedback =
      (customMessage && customMessage.maxLength) ||
      "Should not be more than " + rules.maxLength + " characters.";
  } else if (rules.isNumeric) {
    const pattern = /^\d+$/;
    if (!pattern.test(value)) {
      feedback = "Please provide a number.";
    }
  } else if (rules.existingName) {
    feedback = customMessage.existingName || "This name already exist.";
  } else if (rules.serverHasErrorFeedback) {
    feedback = customMessage.serverErrorResponse || "Server error.";
  } else if (rules.customError) {
    feedback = customMessage || "This is invalid.";
  }

  return (
    <span className="d-flex">
      <span>
        <i className="simple-icon-exclamation" size="sm" />
      </span>
      <span className="ml-1">{feedback}</span>
    </span>
  );
};

export const addCounterTxt = () => {
  return true;
};

export const formDataIsValid = (formData) => {
  let data = true;
  for (let key in formData) {
    if (formData[key].validation.required) {
      data = data && formData[key].valid;
    } else if (formData[key].touched) {
      data = data && formData[key].valid;
    }
  }
  return data;
};

export const capitalize = (string) => startCase(toLower(string));

export const getInitials = (str) => {
  if (str === undefined || str.length === 0) {
    return "";
  } else if (str === "Unknown User") {
    return "?";
  }

  const matches = str.match(/\b(\w)/g);
  if (matches) {
    return matches.join("").slice(0, 3);
  } else {
    return str;
  }
};

export const sumChars = (str) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
};

// export const toTitle = string => string.toTitleCase();
export const toTitle = (string) =>
  trim(string).replace(/\s\s+/g, " ").toTitleCase();

export const joinClasses = (classNames) => compact(classNames).join(" ");

export const addIdToData = (object) =>
  map(object, (val, key) => ({ ...val, id: key }));

export const removeWhitespace = (str) => str.replace(/ /g, "");

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const makeId = (length = 5) => {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const hashCode = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i) => {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

export const getColorByString = (str) => {
  let newStr = str;

  if (str === undefined || str.length === 0) {
    newStr = "?";
  }

  return intToRGB(hashCode(newStr));
};

export const fromNowOrTimestamp = (date) => {
  if (date) {
    // If date is less than or equal to 7 days ago display fromNow else display date.
    const daysAgo = moment().diff(date, "days");
    if (daysAgo <= 7) {
      return date.fromNow();
    } else {
      return date.format("D MMM Y");
    }
  } else {
    return "";
  }
};

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export const getMoment = (date) => {
  switch (typeof date) {
    case "number":
      return moment.unix(date);
    default:
      return moment(date);
  }
};

export const pruneEmpty = (obj) => {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);

    return current;
  })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const makeDataCompatible = (data, fixAllCaps = false) =>
  data.map(({ name, ...other }) => {
    const fixedName = fixAllCaps ? name.toLowerCase() : name;
    return {
      label: toTitle(fixedName),
      value: toTitle(fixedName),
      ...other,
    };
  });

export const timeConverter24To12 = (time) => {
  return moment(time, ["HH:mm"]).format("hh:mm A");
};

export const isNillBlank = (data) => isNil(data) || isEmpty(data);

export const objectAllEmptyVals = (obj) => {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] !== "") return false;
  }
  return true;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getLocalISOTime = (date = new Date()) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(Date.now() - tzoffset).toISOString();
};

export function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

export function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf('.') -1 >>> 0) + 2);
}

export const anonArray = (length) => Array.from(Array(length).keys());