import { TOGGLE_SCHOOL_YEAR } from "./actions";

const globalSY = process.env.REACT_APP_GLOBAL_SY;
const initState = {
  global: globalSY,
  current: globalSY,
};

const syReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_SCHOOL_YEAR:
      return {
        ...state,
        current: action.sy,
      };
    default:
      return {
        ...state,
      };
  }
};
export default syReducer;
