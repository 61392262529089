import { addActivityLog } from "../logs/actions";
import {
  STUDENTS,
  ATTENDANCE,
  IDX_STUDENTS,
  VALUES,
} from "../../constants/collections";
import { addStudentToSchool } from "../../helpers/schools";
import {
  addSchoolToStudent,
  addSectionToStudent,
  setSectionClassPopulation,
  addSectionClassesToStudent,
} from "../../helpers/students";
import { addStudentToSection } from "../../helpers/sections";
import { asyncForEach } from "../../helpers/Utils";
import includes from "lodash/includes";
import map from "lodash/map";
import { getUserAccount } from "../../helpers/users";
import { addStudentToClass } from "../../helpers/classes";

export const QUERY_NEW_STUDENT_START = "QUERY_NEW_STUDENT_START",
  QUERY_NEW_STUDENT_SUCCESS = "QUERY_NEW_STUDENT_SUCCESS",
  QUERY_NEW_STUDENT_FAIL = "QUERY_NEW_STUDENT_FAIL",
  CREATE_STUDENT_START = "CREATE_STUDENT_START",
  CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS",
  CREATE_STUDENT_FAIL = "CREATE_STUDENT_FAIL",
  FETCH_SECTION_STUDENTS_START = "FETCH_SECTION_STUDENTS_START",
  FETCH_SECTION_STUDENTS_SUCCESS = "FETCH_SECTION_STUDENTS_SUCCESS",
  FETCH_SECTION_STUDENTS_FAIL = "FETCH_SECTION_STUDENTS_FAIL",
  EDIT_STUDENT = "EDIT_STUDENT",
  FETCH_CLASS_STUDENTS_START = "FETCH_CLASS_STUDENTS_START",
  FETCH_CLASS_STUDENTS_SUCCESS = "FETCH_CLASS_STUDENTS_SUCCESS",
  FETCH_CLASS_STUDENTS_FAIL = "FETCH_CLASS_STUDENTS_FAIL",
  ADD_VALUES_MARK = "ADD_VALUES_MARK",
  ADD_EXISTING_STUDENTS = "ADD_EXISTING_STUDENTS",
  UNENROLL_STUDENTS = "UNENROLL_STUDENTS",
  ENROLL_BACKSUBJECT = "ENROLL_BACKSUBJECT",
  UNENROLL_BACKSUBJECT = "UNENROLL_BACKSUBJECT";

const queryNewStudentStart = () => ({
  type: QUERY_NEW_STUDENT_START,
});

const queryNewStudentSuccess = () => ({
  type: QUERY_NEW_STUDENT_SUCCESS,
});

const queryNewStudentFail = (error) => ({
  type: QUERY_NEW_STUDENT_FAIL,
  error,
});

export const queryNewStudent =
  ({ student, studentId, edit }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(queryNewStudentStart());
    try {
      const db = getFirestore();
      const studentRef = db.collection(STUDENTS);
      const studentLRNQuery = studentRef.where("lrn", "==", student.lrn);
      const studentLRNSnap = await studentLRNQuery.get();
      if (!edit && !studentLRNSnap.empty) {
        throw new Error("query-error-lrn");
      } else if (edit && !studentLRNSnap.empty) {
        const docIds = map(studentLRNSnap.docs, "id");
        if (!includes(docIds, studentId)) {
          throw new Error("query-error-lrn");
        }
      }
      const studentIdxRef = db.collection(IDX_STUDENTS);
      const studentNameQuery = studentIdxRef.where(
        "keywords",
        "array-contains",
        `${student.firstName.toLowerCase()} ${student.middleName.toLowerCase()} ${student.lastName.toLowerCase()}&all&all`
      );
      const studentNameSnap = await studentNameQuery.get();
      if (!edit && !studentNameSnap.empty) {
        throw new Error("query-error-fullname");
      } else if (edit && !studentLRNSnap.empty) {
        const docIds = map(studentLRNSnap.docs, "id");
        if (!includes(docIds, studentId)) {
          throw new Error("query-error-fullname");
        }
      }
      dispatch(queryNewStudentSuccess());
    } catch (error) {
      console.log(error);
      let errorBody = error;
      if (error.message === "query-error-lrn") {
        errorBody = {
          message: "That LRN is already taken.",
          field: "lrn",
        };
      } else if (error.message === "query-error-fullname") {
        errorBody = {
          message: "That student's name is already taken.",
          field: "fullname",
        };
      }
      dispatch(queryNewStudentFail(errorBody));
    }
  };

const createNewStudentStart = (newStudent) => ({
  type: CREATE_STUDENT_START,
  newStudent,
});

const createNewStudentSuccess = () => ({
  type: CREATE_STUDENT_SUCCESS,
});

const createNewStudentFail = (error) => ({
  type: CREATE_STUDENT_FAIL,
  error: error,
});

export const createNewStudent =
  ({ student, school, sy, sectionId, studentId, edit }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(
      createNewStudentStart(
        [
          student.firstName,
          student.middleName,
          student.lastName,
          student.extName,
        ].join(" ")
      )
    );
    try {
      const db = getFirestore();
      const newStudentRef = !edit
        ? db.collection(STUDENTS).doc()
        : db.collection(STUDENTS).doc(studentId);
      if (!edit) {
        await newStudentRef.set({
          ...student,
          createdOn: db.FieldValue.serverTimestamp(),
          lastUpdated: db.FieldValue.serverTimestamp(),
          deleted: false,
        });
      } else {
        await newStudentRef.set(
          {
            ...student,
            lastUpdated: db.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      }

      dispatch(createNewStudentSuccess());

      if (!edit && school) {
        // Add document to subcollection "schools" if schoolID is present
        await Promise.all([
          addSchoolToStudent(newStudentRef.id, school, sy),
          addStudentToSchool(school, newStudentRef.id, sy, student.gender),
        ]);
      }

      if (!edit && sectionId) {
        await Promise.all([
          // Add student to subcollection "students" of section
          addStudentToSection(sectionId, newStudentRef.id, sy),
          // Add section to subcollection "sections" of student
          addSectionToStudent(newStudentRef.id, sectionId, sy),
          // Add section classes to subcollection "classes" of student, which includes adding student to the each class
          addSectionClassesToStudent(newStudentRef.id, sectionId, sy),
        ]);
      }

      const newStudentDoc = await newStudentRef.get();
      const newStudent = { ...newStudentDoc.data(), id: newStudentDoc.id };
      dispatch(
        addActivityLog({
          doc: newStudent,
          action: !edit ? "createNewStudent" : "updatedStudent",
          actor: !edit ? student.createdBy : student.updatedBy,
        })
      );
    } catch (error) {
      dispatch(createNewStudentFail(error));
    }
  };

const fetchSectionStudentsStart = (section) => ({
  type: FETCH_SECTION_STUDENTS_START,
  section,
});

const fetchSectionStudentsSuccess = (section, students, populationStat) => ({
  type: FETCH_SECTION_STUDENTS_SUCCESS,
  students,
  section,
  populationStat,
});

const fetchSectionStudentsFail = (error) => ({
  type: FETCH_SECTION_STUDENTS_SUCCESS,
  error,
});

export const fetchSectionStudents =
  ({ sectionStudents, section, getAttendance, attendanceId }) =>
  async (dispatch, getState, { getFirestore }) => {
    dispatch(fetchSectionStudentsStart(section));
    try {
      const db = getFirestore();
      const fetchedStudents = [];
      await asyncForEach(sectionStudents, async (studentId) => {
        const studentRef = db.collection(STUDENTS).doc(studentId);
        const studentSnap = await studentRef.get();
        if (studentSnap.exists) {
          let student = { id: studentSnap.id, ...studentSnap.data() };
          if (getAttendance) {
            const studentAttendanceRef = db
              .collection(STUDENTS)
              .doc(studentId)
              .collection(ATTENDANCE)
              .doc(attendanceId);
            const studentAttendanceSnap = await studentAttendanceRef.get();
            let studentAttendance = null;
            if (studentAttendanceSnap.exists) {
              studentAttendance = {
                id: studentAttendanceSnap.id,
                ...studentAttendanceSnap.data(),
              };
            }
            student = { ...student, attendance: studentAttendance };
          }
          fetchedStudents.push(student);
        }
      });
      const sectionPopulation = setSectionClassPopulation(fetchedStudents);
      dispatch(
        fetchSectionStudentsSuccess(section, fetchedStudents, sectionPopulation)
      );
    } catch (error) {
      dispatch(fetchSectionStudentsFail(error));
    }
  };

const fetchClassStudentsStart = (subjectClass) => ({
  type: FETCH_CLASS_STUDENTS_START,
  subjectClass,
});

const fetchClassStudentsSuccess = (subjectClass, students) => ({
  type: FETCH_CLASS_STUDENTS_SUCCESS,
  subjectClass,
  students,
});

const fetchClassStudentsFail = (error) => ({
  type: FETCH_CLASS_STUDENTS_FAIL,
  error,
});

export const fetchClassStudents =
  ({ classRecord, classId, includeUserAcct }) =>
  async (dispatch, getState, { getFirestore }) => {
    dispatch(fetchClassStudentsStart(classId));
    try {
      const db = getFirestore();
      const fetchedStudents = [];
      await asyncForEach(classRecord, async (record) => {
        const recordId = !includeUserAcct ? record.id : record;
        const studentRef = db.collection(STUDENTS).doc(recordId);
        const studentSnap = await studentRef.get();
        if (studentSnap.exists) {
          const recordData = !includeUserAcct
            ? record
            : { userAccount: await getUserAccount(studentSnap.id, "student") };
          const student = {
            id: studentSnap.id,
            ...studentSnap.data(),
            ...recordData,
          };
          fetchedStudents.push(student);
        }
      });
      dispatch(fetchClassStudentsSuccess(classId, fetchedStudents));
    } catch (error) {
      dispatch(fetchClassStudentsFail(error));
    }
  };

export const addValuesMark =
  ({ studentId, sy, marks, quarter }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const db = getFirestore();
      const studentValuesRef = db
        .collection(STUDENTS)
        .doc(studentId)
        .collection(VALUES)
        .doc(sy);

      await studentValuesRef.set(
        {
          marks: {
            [quarter]: marks,
          },
        },
        { merge: true }
      );

      dispatch({ type: ADD_VALUES_MARK });
    } catch (error) {
      throw error.message;
    }
  };

export const addExistingStudents =
  ({ students, schoolId, sy, method, sectionId }) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      const db = getFirestore();
      async function processAdding(studentId, gender) {
        // Add document to subcollection "schools" if schoolID is present
        await addSchoolToStudent(studentId, schoolId, sy);
        await addStudentToSchool(schoolId, studentId, sy, gender);

        // Add student to subcollection "students" of section
        await addStudentToSection(sectionId, studentId, sy);
        // Add section to subcollection "sections" of student
        await addSectionToStudent(studentId, sectionId, sy);
        // Add section classes to subcollection "classes" of student, which includes adding student to the each class
        await addSectionClassesToStudent(studentId, sectionId, sy);
      }

      if (method === "fromSY") {
        // students here is the SY_SECTIONID
        let studentsRef = db
          .collection(IDX_STUDENTS)
          .where("sy_sections", "array-contains", students);

        const querySnap = await studentsRef.get();
        if (!querySnap.empty) {
          querySnap.forEach((doc) => {
            const student = doc.data();
            return processAdding(doc.id, student.gender);
          });
        }
      } else {
        // students here is array of value-label collection
        students.forEach((students) => {
          processAdding(students.value, students.gender);
        });
      }
      dispatch({ type: ADD_EXISTING_STUDENTS });
    } catch (error) {
      throw error;
    }
  };

export const unenrollStudents =
  ({ students, sy, section, school }) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      students.forEach(async (student) => {
        // Unenroll by setting 4th param to true
        await addStudentToSection(section, student.id, sy, true);
        // console.log("Removed student in section:", studentId)
        await addSectionToStudent(student.id, section, sy, true);
        // console.log("Removed section from student:", studentId)
        await addSectionClassesToStudent(student.id, section, sy, true);
        // console.log("Removed section classes from student:", studentId)
        await addStudentToSchool(school, student.id, sy, student.gender, true);
      });
      dispatch({ type: UNENROLL_STUDENTS });
    } catch (error) {
      throw error;
    }
  };

export const enrollBackSubject =
  ({ students, sy, method, classId, unenroll }) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      const db = getFirestore();
      async function processAdding(studentId, gender) {
        await addStudentToClass(classId, studentId, sy, unenroll);
      }

      if (method === "fromSY") {
        // students here is the SY_SECTIONID
        let studentsRef = db
          .collection(IDX_STUDENTS)
          .where("sy_sections", "array-contains", students);

        const querySnap = await studentsRef.get();
        if (!querySnap.empty) {
          querySnap.forEach((doc) => processAdding(doc.id));
        }
      } else {
        // students here is array of value-label collection
        students.forEach((student) => {
          const enrolledStudent = unenroll ? student : student.value;
          processAdding(enrolledStudent);
        });
      }
      dispatch({ type: unenroll ? UNENROLL_BACKSUBJECT : ENROLL_BACKSUBJECT });
    } catch (error) {
      throw error;
    }
  };
