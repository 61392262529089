import {
  CREATE_SECTION_START,
  CREATE_SECTION_FAIL,
  CREATE_SECTION_SUCCESS,
  CREATE_MASTER_GRADING_SHEET_START,
  CREATE_MASTER_GRADING_SHEET_SUCCESS,
  CREATE_MASTER_GRADING_SHEET_FAIL,
} from "./actions";

const initState = {
  creating: false,
  created: false,
  createError: null,
  newSectionName: "",
  fetchingStudents: false,
  fetchedStudents: false,
  fetchStudentError: null,
  masterGradingSheet: {},
};

const sectionReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_SECTION_START:
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
        newSectionName: action.newSection,
      };
    case CREATE_SECTION_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
        createError: null,
      };
    case CREATE_SECTION_FAIL:
      return {
        creating: false,
        created: false,
        createError: action.error,
      };
    case CREATE_MASTER_GRADING_SHEET_START:
      return {
        ...state,
        masterGradingSheet: {
          ...state.masterGradingSheet,
          [`${action.section}_${action.sy}`]: {
            ...state.masterGradingSheet[`${action.section}_${action.sy}`],
            creating: true,
            created: false,
            error: null,
          },
        },
      };
    case CREATE_MASTER_GRADING_SHEET_SUCCESS:
      return {
        ...state,
        masterGradingSheet: {
          ...state.masterGradingSheet,
          [`${action.section}_${action.sy}`]: {
            ...state.masterGradingSheet[`${action.section}_${action.sy}`],
            data: action.gradingSheetData,
            creating: false,
            created: true,
          },
        },
      };
    case CREATE_MASTER_GRADING_SHEET_FAIL:
      return {
        ...state,
        masterGradingSheet: {
          ...state.masterGradingSheet,
          [`${action.section}_${action.sy}`]: {
            ...state.masterGradingSheet[`${action.section}_${action.sy}`],
            creating: false,
            created: false,
            error: action.error,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default sectionReducer;
