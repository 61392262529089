import firebase from "../constants/firebase";
import {
  SCHOOLS,
  EMPLOYEES,
  ADVISORIES,
  CLASSES,
  IDX_EMPLOYEES
} from "../constants/collections";
import { pick } from "lodash-es";

export const addSchoolToEmployee = async (employee, school, sy) => {
  const db = firebase.firestore();
  const employeeRef = db
    .collection(EMPLOYEES)
    .doc(employee)
    .collection(SCHOOLS)
    .doc(sy);
  return await employeeRef.set(
    {
      schools: firebase.firestore.FieldValue.arrayUnion(school),
    },
    { merge: true }
  );
};

export const addAdvisoryToEmployee = async (employee, sy, sectionId) => {
  const db = firebase.firestore();
  const employeeRef = db
    .collection(EMPLOYEES)
    .doc(employee)
    .collection(ADVISORIES)
    .doc(sy);
  return await employeeRef.set(
    { [ADVISORIES]: firebase.firestore.FieldValue.arrayUnion(sectionId) },
    { merge: true }
  );
};

export const addClassToTeacher = async (teacher, sy, classId) => {
  const db = firebase.firestore();
  const employeeRef = db
    .collection(EMPLOYEES)
    .doc(teacher)
    .collection(CLASSES)
    .doc(sy);
  return await employeeRef.set(
    {
      [CLASSES]: firebase.firestore.FieldValue.arrayUnion(classId),
    },
    { merge: true }
  );
};

export const removeClassToTeacher = async (teacher, sy, classId) => {
  const db = firebase.firestore();
  const employeeRef = db
    .collection(EMPLOYEES)
    .doc(teacher)
    .collection(CLASSES)
    .doc(sy);
  return await employeeRef.set(
    {
      [CLASSES]: firebase.firestore.FieldValue.arrayRemove(classId),
    },
    { merge: true }
  );
};

export const removeAdvisoryToEmployee = async (employee, sy, sectionId) => {
  const db = firebase.firestore();
  const employeeRef = db
    .collection(EMPLOYEES)
    .doc(employee)
    .collection(ADVISORIES)
    .doc(sy);
  return await employeeRef.set(
    { [ADVISORIES]: firebase.firestore.FieldValue.arrayRemove(sectionId) },
    { merge: true }
  );
};

export const fetchEmployees = async ({
  school,
  sy,
  search,
  teaching,
  limit,
  sort,
  order,
  gender,
  position,
}) => {
  try {
    const db = firebase.firestore();
    const keywords = [search.toLowerCase(), sy, school].join("&");
    const employeesIndexRef = db.collection(IDX_EMPLOYEES);
    const positionQuery =
      position === "All" ? {operator: "!=", val: ""} : {operator: "==", val: position}
    const genderQuery =
      teaching === "All" ? ["Male", "Female"] : [gender];   

    const employeesIndexSnap = await employeesIndexRef
      .where("keywords", "array-contains", keywords)
      .where("teaching", "==", teaching)
      .where("position.value", positionQuery.operator, positionQuery.val)
      .where("gender", "in", genderQuery)
      .orderBy(order, sort)
      .limit(limit)
      .get();

    let count = 0,
      employees = [];

    if (!employeesIndexSnap.empty) {
      employeesIndexSnap.forEach((doc) => {
        count = count + 1;
        const employeeData = pick(doc.data(), [
          "name",
          "profileId",
          "profileType",
          "position",
          "photo",
          "gender"
        ]);
        employees.push({ id: doc.id, ...employeeData });
      });
    }
    return { employees, count };
  } catch (error) {
    throw error;
  }
};

export const searchEmployee = async (teaching, sy, schoolId, search, limit = 0) => {
  try {
    const db = firebase.firestore();
    const keywords = [search.toLowerCase(), sy, schoolId].join("&");
    let employeeRef = db
      .collection(IDX_EMPLOYEES)
      .where("keywords", "array-contains", keywords)
      .where("teaching", "==", teaching)
      console.log(limit)
    if (limit) {
      employeeRef.limit(limit)
    }
    return await employeeRef.get();
  } catch (error) {
    throw error
  }
};