import { addActivityLog } from '../logs/actions';
import { USER_REGISTRATIONS, EMPLOYEES, STUDENTS, USER_ACCOUNTS, SCHOOLS } from '../../constants/collections';
import { roles } from '../../constants/userAccounts';
import find from 'lodash/find';
import { getProfileSchools } from '../../helpers/schools';

export const ADD_USER_ACCOUNT_START = 'ADD_USER_ACCOUNT_START',
	ADD_USER_ACCOUNT_SUCCESS = 'ADD_USER_ACCOUNT_SUCCESS',
	ADD_USER_ACCOUNT_FAIL = 'ADD_USER_ACCOUNT_FAIL';

const addUserAccountStart = (profile) => ({
	type: ADD_USER_ACCOUNT_START,
	profile
});

const addUserAccountSuccess = () => ({
	type: ADD_USER_ACCOUNT_SUCCESS
});

const addUserAccountFail = (error) => ({
	type: ADD_USER_ACCOUNT_FAIL,
	error
});

export const addUserAccount = (profile, resend) => async (dispatch, getState, { getFirestore }) => {
	dispatch(addUserAccountStart(profile.name));
	try {
		const db = getFirestore();
		// If Resend - update existing canceled = true, send another
		if (resend && resend.edit) {
			await db.collection(USER_REGISTRATIONS).doc(resend.regId).set(
				{
					canceled: true,
					lastUpdated: db.FieldValue.serverTimestamp(),
					canceledBy: profile.createdBy
				},
				{ merge: true }
			);
		}

		let profileSchools = [];
		if (profile.profileType === 'employee' || profile.profileType === 'student') {
			const schools = await getProfileSchools(profile.profileType, profile.profileId, profile.sy);
			profileSchools = schools[SCHOOLS];
		}
		const newRegistrationRef = db.collection(USER_REGISTRATIONS).doc();

		await newRegistrationRef.set(
			{
				...profile,
				canceled: false,
				completed: false,
				createdOn: db.FieldValue.serverTimestamp(),
				lastUpdated: db.FieldValue.serverTimestamp(),
				deleted: false,
				role: find(roles, ['value', profile.role]) || null,
				schools: profileSchools
			},
			{ merge: true }
		);

		if (profile.profileId) {
			// Update employee or student
			const col = profile.profileType === 'employee' ? EMPLOYEES : STUDENTS;
			const colRef = db.collection(col).doc(profile.profileId);
			await colRef.set(
				{
					email: profile.email,
					lastUpdated: db.FieldValue.serverTimestamp(),
					updatedBy: profile.createdBy
				},
				{ merge: true }
			);
			// Add user account to the employee or student
			const userAccountRef = colRef.collection(USER_ACCOUNTS).doc(profile.role);
			await userAccountRef.set(
				{
					role: profile.role,
					status: 'pending',
					registrationId: newRegistrationRef.id
				},
				{ merge: true }
			);
		}
		const newRegistrationDoc = await newRegistrationRef.get();
		const newRegistration = { ...newRegistrationDoc.data(), id: newRegistrationDoc.id };
		dispatch(
			addActivityLog({
				doc: newRegistration,
				action: 'createNewRegistration',
				actor: profile.createdBy
			})
		);
		dispatch(addUserAccountSuccess());
	} catch (error) {
		dispatch(addUserAccountFail(error));
	}
};
